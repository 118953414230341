import React from "react";
import { useState, useEffect } from "react";
import {
  CRM_BASE_URL_FMS,
  GENERAL_SETTING_BASE_URL,
} from "../../../../api/bootapi";
import PublicFetch from "../../../../utils/PublicFetch";
import moment from "moment";
import { useParams } from "react-router-dom";
import "./print_taskexp.scss";
import TaskExpensePrint from "../../../../components/Task Expense Print/task_expense_print";
import { camelize } from "../../../../utils/camelcaseconvert";

function Print_taskepence() {
  var converter = require("number-to-words");
  const { id } = useParams();
  const [alljobs, setAlljobs] = useState();
  const [alljobss, setAlljobss] = useState();
  const [blobUrl, setBlobUrl] = useState();
  const [cmpnyHeaderImg, setCmpnyHeaderImg] = useState();
  const [isContentData, setIsContentData] = useState(false);
  const [isCompanyInfo, setIscompantInfo] = useState(false);
  const [totalcost, setTotalCost] = useState();

  const getSingleJob = () => {
    PublicFetch.get(`${CRM_BASE_URL_FMS}/job/${id}`)
      .then((res) => {
        if (res.data.success) {
          let temp = [];

          setAlljobs(res?.data?.data);
          let totalSelling = 0;
          let totalActual = 0;
          res?.data?.data?.fms_v1_job_task_expenses?.forEach((item, index) => {
            temp.push({
              task_expence_name: item?.crm_v1_services.service_name,
              task_expence_agent_name:
                item?.agents?.crm_v1_vendors?.vendor_name,
              task_expence_currency:
                item
                  ?.generalsettings_v1_currency_fms_v1_job_task_expenses_job_task_expense_cost_currTogeneralsettings_v1_currency
                  .currency_code,
              task_expence_exchange: item?.job_task_expense_cost_exch,
              task_expence_amount: item?.job_task_expense_cost_amountfx,
              task_expence_total_lx: item?.job_task_expense_cost_subtotal_lx,
              task_expence_amount_lx: item?.job_task_expense_exp_amountlx,
              task_expence_amount_fx: item?.job_task_expense_exp_amountfx,
            });

            totalActual += item?.job_task_expense_exp_amountlx;
            totalSelling += item?.job_task_expense_cost_subtotal_lx;
          });
          setTotalCost((prev) => {
            return {
              ...prev,
              totalSellingAmt: totalSelling,
              totalActualAmt: totalActual,
            };
          });
          setAlljobss(temp);

          setIsContentData(true);
        } else {
        }
      })
      .catch((err) => {});
  };
  const getComapanyData = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );
      setCmpnyHeaderImg(allcmpny.data.data[0].company_logo);
      setIscompantInfo(true);
    } catch (err) {}
  };
  useEffect(() => {
    getComapanyData();
    console.log("quotHeaderImg", cmpnyHeaderImg);
    if (cmpnyHeaderImg) {
      PublicFetch.get(`${process.env.REACT_APP_BASE_URL}/${cmpnyHeaderImg}`, {
        responseType: "blob",
      })
        .then((resp) => {
          const blobUrl = URL.createObjectURL(resp.data);

          setBlobUrl(blobUrl);
        })
        .catch((err) => {});
    }
  }, [cmpnyHeaderImg]);

  useEffect(() => {
    if (id) {
      getSingleJob();
    }
  }, [id]);

  useEffect(() => {
    if (isCompanyInfo === true && isContentData === true && blobUrl) {
      window.print();
    }
  }, [isCompanyInfo, isContentData, blobUrl]);

  return (
    <div>
      <TaskExpensePrint
        invoiceHeaderImg={blobUrl}
        invoice_no
        billto
        Invoice_type="JOB"
        invoice_number={alljobs?.job_number}
        bankDetailsView={false}
        invoice_details1={
          <>
            <tr className="p-2 ">
              <td>Job No </td>
              <td>: </td>
              <td className="quotation_p_name">{alljobs?.job_number}</td>
            </tr>
            <tr className="p-2">
              <td>Job Date </td>
              <td>: </td>
              <td className="quotation_p_name">
                {moment(alljobs?.job_date).format("DD-MM-YYYY")}
              </td>
            </tr>
            <tr className="p-2">
              <td>Consignee</td>
              <td>: </td>
              <td className="quotation_p_name">{alljobs?.job_consignee}</td>
            </tr>
          </>
        }
        invoice_details2={
          <>
            <tr className="p-2 ">
              <td>Shipper </td>
              <td>: </td>
              <td className="quotation_p_name">{alljobs?.job_shipper}</td>
            </tr>

            <tr className="p-2">
              <td>Job Booking No</td>
              <td>: </td>
              <td className="quotation_p_name">
                {" "}
                {alljobs?.fms_v1_job_bookings?.job_booking_no}
              </td>
            </tr>
            <tr className="p-2">
              <td>AWB/BL No </td>
              <td>: </td>
              <td className="quotation_p_name"> {alljobs?.job_awb_bl_no}</td>
            </tr>
          </>
        }
        invoice_table_header={
          <>
            <tr>
              <th scope="col" className="font_weight_qt">
                SL NO.
              </th>
              <th
                scope="col"
                className="font_weight_qt  task_width text_align_words"
              >
                TASKS
              </th>
              <th scope="col" className="font_weight_qt  text_align_words">
                AGENT
              </th>
              <th
                scope="col"
                className="font_weight_qt "
                colSpan="4"
                style={{ textAlign: "center" }}
              >
                SELLING
              </th>
              <th
                scope="col"
                className="font_weight_qt  text_align_words"
                colSpan={4}
                style={{ textAlign: "center" }}
              >
                ACTUAL COST
              </th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th scope="col" className="font_weight_qt text_align_words">
                Curr
              </th>
              <th scope="col" className="font_weight_qt  text_align_words">
                Exch
              </th>
              <th scope="col" className="font_weight_qt  text_align_words">
                Amount
              </th>
              <th scope="col" className="font_weight_qt text_align_words">
                Total Lx
              </th>
              <th scope="col" className="font_weight_qt  text_align_words">
                Curr
              </th>
              <th scope="col" className="font_weight_qt  text_align_words">
                Exch
              </th>
              <th scope="col" className="font_weight_qt  text_align_words">
                Amount Fx
              </th>
              <th scope="col" className="font_weight_qt text_align_words">
                Amount Lx
              </th>
            </tr>
          </>
        }
        invoice_table_data={
          <>
            {alljobss &&
              alljobss.map((itm, indx) => (
                <tr>
                  <td className="">{indx + 1} </td>
                  <td className=" text_align_words">
                    {itm.task_expence_name}{" "}
                  </td>
                  <td className=" text_align_number">
                    {itm.task_expence_agent_name}
                  </td>
                  <td className="text_align_words">
                    {itm.task_expence_currency}{" "}
                  </td>
                  <td className="text_align_words">
                    {itm.task_expence_exchange?.toFixed(3)}
                  </td>
                  <td className="text_align_words">
                    {itm?.task_expence_amount?.toFixed(3)}
                  </td>
                  <td className="text_align_words">
                    {itm.task_expence_total_lx}
                  </td>
                  <td className=" text_align_number">
                    {itm.task_expence_currency}
                  </td>
                  <td className="text_align_number">
                    {itm.task_expence_exchange?.toFixed(3)}
                  </td>
                  <td className=" text_align_words">
                    {itm.task_expence_amount_fx?.toFixed(3)}
                  </td>
                  <td className=" text_align_words">
                    {itm.task_expence_amount_lxtotalSellingAmt}
                  </td>
                </tr>
              ))}
          </>
        }
        total={totalcost?.totalSellingAmt?.toFixed(3)}
        total2={totalcost?.totalActualAmt?.toFixed(3)}
        amount_in_words={
          totalcost?.totalSellingAmt &&
          camelize(converter.toWords(totalcost?.totalSellingAmt))
        }
        amount_in_words2={
          totalcost?.totalActualAmt &&
          camelize(converter.toWords(totalcost?.totalActualAmt))
        }
      />
    </div>
  );
}

export default Print_taskepence;
