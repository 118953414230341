import React, { lazy, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import ROUTES from "./routes";
import ROUTES from "./routes";
import "antd/dist/antd.css";
import "../src/api/scss/index.scss";
import TestPage from "./pages/testpage";

import Purchaseorderinvoice from "./pages/FMS/Purchase/Purchase Order/purcahse_invoice";

import Quotationinvoice from "./pages/FMS/Quotations/create quotation/quotation_invoice";
import Jobinvoice from "./pages/FMS/Job/Job_invoice/job_invoice";

import ProtectedRoute from "./components/ProtectedRoute";
import InvoicePrint from "./components/Invoice/InvoicePrint";
import PrintInvoice from "./pages/FMS/Invoice/PrintInvoice";

import Invoicetemp1 from "./components/Invoice/invoicetemp1/invoicetemp1";
import Invoicetemplate2 from "./components/Invoice/invoicetemp2/invoicetemp2";
import Invoicetemplate3 from "./components/Invoice/invoicetemp3/invoicetemp3";

import IsDeniedperm from "./DeniedPermission/IsDeniedperm";
import PageNotFound from "./pageNotFound/PageNotFound";
import Custom_model from "./components/custom_modal/custom_model";
import Printdata from "./components/printpages/print";
import Arrivalnoticeprint from "./pages/FMS/Job/ArrivalNoticePrint/Arrivalnoticeprint";

import CustomerProtectedRoute from "./components/CustomerProtectedRoute/CustomerProtectedRoute";
import ReceiptPrint from "./pages/Accounts/AccountReceipts/ReceiptPrint";
import CostandexpensePrint from "./pages/FMS/CustomerwiseCostAndExpenseReport/costandexpensePrint";
import MonthlyreportPrint from "./pages/FMS/Monthly report/monthlyreportPrint";
import InvoicereportPrint from "./pages/FMS/Invoice/InvoiceReport/InvoicereportPrint";
import DailysalesreportPrint from "./pages/FMS/DailysalesReport/DailysalesreportPrint";
import AgentreportPrint from "./pages/FMS/AgentReport/agentreportPrint";
import PurchasebillreportPrint from "./pages/FMS/Purchase/Purchase bill report/purchase_billreportPrint";
import StatementofAccountsPrint from "./pages/Accounts/Reports/statementOfAccounts/statementofAccountsPrint";
import PendingInvoicesPrint from "./pages/Accounts/Reports/Pending Invoices/PendingInvoicesPrint";
import AgeingreportPrint from "./pages/Accounts/Reports/AgeingReport/AgeingreportPrint";
import TrialbalancePrint from "./pages/Accounts/Reports/TrialBalance/TrialbalancePrint";
import AwbBlReportPrint from "./pages/FMS/Awb_bl_report/Awb_blReportPrint";
import PendingBookingPrint from "./pages/FMS/ShipmentPendingBooking/PendingBookingPrint";
// import ShipmentPendingJobPrint from "./components/ShipmentPendingJobsPrint/ShipmentPendingJobPrint.jsx";
import PendingJobPrint from "./pages/FMS/ShipmentPendingJobReport/PendingJobPrint.jsx";
import PrintPurchaseBill from "./pages/FMS/Purchase/Purchasebill/PrintPurchaseBill.jsx";
import CustomerwiseReportPrint from "./pages/FMS/CustomerWiseReport/CustomerwisereportPrint.jsx";
import SupplierCreditPrint from "./pages/Accounts/SupplierCreditNote/SupplierCreditPrint.jsx";
import CreditNotePrint from "./pages/Accounts/CreditNotes/CreditNotePrint.jsx";
import DebitNotePrint from "./pages/Accounts/DebitNotes/DebitnotesPrint.jsx";
import InvoicePrintTemp4 from "./components/Invoice/invoicetemp4/invoicetemp4.jsx";
import InvoicePrintTemp5 from "./components/Invoice/invoicetemp5/invoicetemp5.jsx";
import PaymentPrint from "./pages/Accounts/Payments/PaymentPrint";
import PrintDeliveryNote from "./pages/FMS/Job/PrintDeliveryNote.jsx";
import Print_taskepence from "./pages/FMS/Job/Task and Expenses/Print_taskepence.jsx";
import RefundHistory from "./pages/Accounts/Reports/refundHistory/RefundHistory.jsx";

// import SupplierCreditNotePrint from "./pages/Accounts/SupplierCreditNote";

// Lazy Functions starts
const Dashboard = lazy(() => import("./pages/dashboard/dashboardnew"));

const Crm_tab = lazy(() => import("./pages/dashboard/crm tab/crm_tab"));

const NewLeadList = lazy(() => import("./pages/CRM/NewLead/NewLeadList"));

const Convertcustomer = lazy(() => import("./pages/CRM/lead/lead"));

const Lead = lazy(() => import("./pages/CRM/lead/lead"));
const LeadList = lazy(() => import("./pages/CRM/lead/lead_list/lead_list"));
const AddEditLead = lazy(() => import("./pages/CRM/NewLead/AddEditLead"));
const ViewLead = lazy(() => import("./pages/CRM/NewLead/ViewLead"));
const CustomerLogin = lazy(() => import("./pages/Login/CustomerLogin"));
const CustomerDashboard = lazy(() =>
  import("./pages/dashboard/Customer Dashboard/CustomerDashboard")
);
const CustomerJobs = lazy(() =>
  import("./pages/Customer Login Pages/CustomerJobs/CustomerJobs")
);

const CustomerInvoices = lazy(() =>
  import("./pages/Customer Login Pages/CustomerInvoices/CustomerInvoices")
);

const ViewAdvancePayment = lazy(() =>
  import("./pages/Accounts/AdvancePayment/ViewAdvancePayment")
);
const AddEditAdvancePayment = lazy(() =>
  import("./pages/Accounts/AdvancePayment/AddEditAdvancePayment")
);
const AdvancePaymentList = lazy(() =>
  import("./pages/Accounts/AdvancePayment/AdvancePaymentList")
);

const StatementOfAccounts = lazy(() =>
  import("./pages/Accounts/Reports/statementOfAccounts/StatementOfAccounts")
);
const Category = lazy(() => import("./pages/CRM/Selling/category/category"));
const LeadReport = lazy(() => import("./pages/CRM/lead/leadReport/leadReport"));
const Opportunitylist = lazy(() =>
  import("./pages/CRM/lead/opportunity_ List/opportunitylist")
);
const Categorylist = lazy(() =>
  import("./pages/CRM/Selling/category/viewCategory")
);
const CategoryUpdate = lazy(() =>
  import("./pages/CRM/Selling/category/categoryUpdate")
);

const EditOpportunity = lazy(() =>
  import("./pages/CRM/lead/modals/editopportunity")
);
const LeadEdit = lazy(() =>
  import("./pages/CRM/lead/lead_list/edit_lead_list")
);
// const Purchaseorderinvoice = lazy(() =>
//   import("./pages/FMS/Purchase/Purchase Order/purcahse_invoice")
// );
const OpportunityReport = lazy(() =>
  import("./pages/opportunityReport/OpportunityReport")
);
const Attribute = lazy(() =>
  import("./pages/CRM/Selling/attributes/attributes")
);
const Add_Attribute = lazy(() =>
  import("./pages/CRM/Selling/attributes/add_attribute")
);
const BrandCreate = lazy(() =>
  import("./pages/CRM/Selling/brands/BrandCreate")
);
const BrandsList = lazy(() => import("./pages/CRM/Selling/brands/BrandsList"));
const Productlist = lazy(() =>
  import("./pages/CRM/Selling/Product/Productlist")
);
const ProductCreate = lazy(() =>
  import("./pages/CRM/Selling/Product/ProductCreate")
);
const ProductUpdate = lazy(() =>
  import("./pages/CRM/Selling/Product/ProductUpdate")
);

const ProductDetails = lazy(() =>
  import("./pages/CRM/Selling/Product/ProductDetails")
);
const Varients = lazy(() =>
  import("./pages/CRM/Selling/Product/Varient/Varients")
);
const Services = lazy(() =>
  import("./pages/CRM/Selling/services/services_list")
);
const ServiceCreate = lazy(() =>
  import("./pages/CRM/Selling/services/ServiceCreate")
);
const Unitlist = lazy(() => import("./pages/CRM/Selling/unit/Unitlist"));
const Addunit = lazy(() => import("./pages/CRM/Selling/unit/Addunit"));
const Login = lazy(() => import("./pages/Login/login"));
const AddOpportunity = lazy(() =>
  import("./pages/CRM/lead/modals/addopportunity")
);
const Branches = lazy(() => import("./pages/HRMS/branches/branches"));
const Departments = lazy(() => import("./pages/HRMS/departments/departments"));
const Designation = lazy(() => import("./pages/HRMS/designation/designation"));
const EmploymentType = lazy(() =>
  import("./pages/HRMS/employment_type/employment_type")
);
const OpportunityLeadlist = lazy(() =>
  import("./pages/CRM/lead/opportunity_ List/opportunityleadlist")
);
const Permission = lazy(() =>
  import("./pages/HRMS/permissions/permissions.jsx")
);
const Roles_and_Screen = lazy(() =>
  import("./pages/HRMS/Roles and screen/roles_and_screen")
);
const Employees = lazy(() => import("./pages/HRMS/employees/employees"));
const CreateEmployee = lazy(() =>
  import("./pages/HRMS/employees/CreateEmployee")
);
const Employeegrade = lazy(() =>
  import("./pages/HRMS/employeegrade/employeegrade")
);
const Updatevendor = lazy(() =>
  import("./pages/CRM/Purchase/vendor/updatevendor")
);
const Assign_opportunity = lazy(() =>
  import("./pages/FMS/Opportunity_assigns/AssignOpportunity/assign_opportunity")
);
const Track_assignments = lazy(() =>
  import(
    "./pages/FMS/Opportunity_assigns/Track_assignments/track_opportunity_assigns"
  )
);

const UpdateAssignOppurtunity = lazy(() =>
  import(
    "./pages/FMS/Opportunity_assigns/update_oppo_assignment/update_oppo_assignment"
  )
);

const CreateAgent = lazy(() =>
  import("./pages/FMS/Agent mangement/CreateAgent")
);
const Purchaseorder = lazy(() =>
  import("./pages/FMS/Purchase/Purchase Order/purchase_order")
);

const Frightlist = lazy(() =>
  import("./pages/FMS/settings/Fright_types/fright_types")
);
const Quotations = lazy(() =>
  import("./pages/FMS/Quotations/create quotation/quotation_list")
);
const Add_Quotation = lazy(() =>
  import("./pages/FMS/Quotations/create quotation/add_quotation")
);
const EditQuotation = lazy(() =>
  import("./pages/FMS/Quotations/create quotation/edit_quotation")
);
const ViewQuotation = lazy(() =>
  import("./pages/FMS/Quotations/create quotation/view_quotation")
);
const Carrierlist = lazy(() => import("./pages/FMS/settings/Carrier/carrier"));
const ListAgent = lazy(() => import("./pages/FMS/Agent mangement/ListAgent"));
const UpdateAgent = lazy(() =>
  import("./pages/FMS/Agent mangement/Updateagent")
);
const Addvendor = lazy(() => import("./pages/CRM/Purchase/vendor/addvendor"));
const Enquiries = lazy(() =>
  import("./pages/FMS/Opportunity_assigns/Enquiries/Enquiries")
);
const Agent_Response = lazy(() =>
  import("./pages/FMS/Opportunity_assigns/Enquiries/agent_response")
);
const Modelist = lazy(() => import("./pages/FMS/settings/Mode/mode"));
const Assign_oppertunity_edit = lazy(() =>
  import(
    "./pages/FMS/Opportunity_assigns/AssignOpportunity/assign_oppertunity_edit"
  )
);
const Consignee = lazy(() =>
  import("./pages/FMS/settings/Consignee/consignee")
);
const ViewJob = lazy(() => import("./pages/FMS/Job/viewjob"));
const JobClosing = lazy(() => import("./pages/FMS/Job Closing/Jobclosing"));

const PaymentTerms = lazy(() =>
  import("./pages/FMS/settings/Payment Terms/payment_terms")
);
const Currency = lazy(() =>
  import("./pages/General Settings/Currency/currency")
);

const Add_Job_Booking = lazy(() =>
  import("./pages/FMS/Job booking/add_job_booking")
);
const List_job_booking = lazy(() =>
  import("./pages/FMS/Job booking/list_job_booking")
);
const ViewJobBooking = lazy(() =>
  import("./pages/FMS/Job booking/view_job_booking")
);
const TaxType = lazy(() => import("./pages/FMS/settings/Tax_type/tax_type"));
const JobTasks = lazy(() => import("./pages/FMS/settings/Job_tasks/job_tasks"));
const Airport = lazy(() => import("./pages/FMS/settings/Airport/airport"));
const Seaport = lazy(() => import("./pages/FMS/settings/Seaport/seaport"));
const Companyinfo = lazy(() =>
  import("./pages/General Settings/companyinfo/companyinfo")
);
const Locations = lazy(() =>
  import("./pages/FMS/settings/Locations/locations")
);
const SelectCountry = lazy(() =>
  import("./pages/General Settings/country/CountrySelect")
);
const QuotationAssign = lazy(() =>
  import("./pages/FMS/Quotations/QuotationAssign")
);
const Listjob = lazy(() => import("./pages/FMS/Job/joblist"));

const Updatejob = lazy(() => import("./pages/FMS/Job/Jobphase2/Jobcreate"));
const Taskexpenses = lazy(() =>
  import("./pages/FMS/Job/Task and Expenses/add_task_expenses")
);
const InvoiceList = lazy(() => import("./pages/FMS/Invoice/InvoiceList"));
const InvoicePreView = lazy(() => import("./pages/FMS/Invoice/InvoicePreView"));

const EditInvoicePreview = lazy(() =>
  import("./pages/FMS/Invoice/InvoicePreView")
);

// const Quotationinvoice = lazy(() =>
//   import("./pages/FMS/Quotations/create quotation/quotation_invoice")
// );
// const Jobinvoice = lazy(() =>
//   import("./pages/FMS/Job/Job_invoice/job_invoice")
// );
const Vendortype = lazy(() =>
  import("./pages/CRM/Purchase/vendertype/vendortype")
);
const Vendor = lazy(() => import("./pages/CRM/Purchase/vendor/vendor"));
// const ProtectedRoute = lazy(() => import("./components/ProtectedRoute"));
// const InvoicePrint = lazy(() => import("./components/Invoice/InvoicePrint"));
// const PrintInvoice = lazy(() => import("./pages/FMS/Invoice/PrintInvoice"));
const InvoiceView = lazy(() => import("./pages/FMS/Invoice/InvoiceView"));
const DailyExpence = lazy(() =>
  import("./pages/Accounts/DailyExpence/DailyExpence")
);
const AddPayments = lazy(() => import("./pages/Accounts/Payments/AddPayments"));
const CreateExpence = lazy(() =>
  import("./pages/Accounts/DailyExpence/CreateExpence")
);
const ExpenseCategory = lazy(() =>
  import("./pages/Accounts/settings/Expense_Category/ExpenseCategory")
);
const ListpurchaseOrder = lazy(() =>
  import("./pages/FMS/Purchase/Purchase Order/purchase_orderlist")
);

const ViewpurchaseOrder = lazy(() =>
  import("./pages/FMS/Purchase/Purchase Order/view_purchaseorder")
);
const Payment_mode = lazy(() =>
  import("./pages/Accounts/settings/payment_mode")
);
const Add_purchase = lazy(() =>
  import("./pages/Accounts/settings/Purchase/add_purchase")
);
const Purchase = lazy(() =>
  import("./pages/Accounts/settings/Purchase/purchase")
);
const View_purchase = lazy(() =>
  import("./pages/Accounts/settings/Purchase/view_purchase")
);
const Edit_purchase = lazy(() =>
  import("./pages/Accounts/settings/Purchase/edit_purchase")
);
const Print_purchase = lazy(() =>
  import("./pages/Accounts/settings/Purchase/print_purchase")
);
const Credit_notes = lazy(() =>
  import("./pages/Accounts/CreditNotes/list_creditnotes")
);
const Addcredit_notes = lazy(() =>
  import("./pages/Accounts/CreditNotes/add_creditnotes")
);
const Bank = lazy(() => import("./pages/Accounts/settings/BankAccount/bank"));

const CreditnotesView = lazy(() =>
  import("./pages/Accounts/CreditNotes/view_creditnotes")
);
const JobPayments = lazy(() =>
  import("./pages/Accounts/JobPayments/job_payments")
);
const AddJobPayments = lazy(() =>
  import("./pages/Accounts/JobPayments/add_job_payment")
);
const EditJobPayment = lazy(() =>
  import("./pages/Accounts/JobPayments/edit_job_payments")
);
const ViewJobPayment = lazy(() =>
  import("./pages/Accounts/JobPayments/view_job_payments")
);
const Payments = lazy(() => import("./pages/Accounts/Payments/Payments"));

const ViewPayment = lazy(() => import("./pages/Accounts/Payments/ViewPayment"));
const EditExpence = lazy(() =>
  import("./pages/Accounts/DailyExpence/CreateExpence")
);

const ViewdailyExpense = lazy(() =>
  import("./pages/Accounts/DailyExpence/ViewDailyExpence")
);

const AgentReport = lazy(() => import("./pages/FMS/AgentReport/agentReport"));
const CostAndExpenseReport = lazy(() =>
  import("./pages/FMS/CustomerwiseCostAndExpenseReport/costAndExpenseReport")
);
const EnquiryReport = lazy(() =>
  import("./pages/FMS/EnquiryReport/EnquiryReport")
);
const Monthly_report = lazy(() =>
  import("./pages/FMS/Monthly report/monthlyreport")
);
const Invoicereport = lazy(() =>
  import("./pages/FMS/Invoice/InvoiceReport/Invoicereport")
);
const CreditNoteType = lazy(() =>
  import("./pages/Accounts/settings/CreditNoteType/credit_note_type")
);
const Adddebit_notes = lazy(() =>
  import("./pages/Accounts/DebitNotes/add_debitnotes")
);

const Debit_notes = lazy(() =>
  import("./pages/Accounts/DebitNotes/list_debitnotes")
);
const DebitnotesView = lazy(() =>
  import("./pages/Accounts/DebitNotes/view_debitnotes")
);

const ListSupplierCreditnotes = lazy(() =>
  import("./pages/Accounts/SupplierCreditNote/SupplierCreditnoteList")
);

const AddSupplierCreditnotes = lazy(() =>
  import("./pages/Accounts/SupplierCreditNote/SupplierCreditnote")
);
const EditSupplierCreditnotes = lazy(() =>
  import("./pages/Accounts/SupplierCreditNote/SupplierCreditnote")
);
const ViewSupplierCreditnotes = lazy(() =>
  import("./pages/Accounts/SupplierCreditNote/ViewSupplierCreditnote")
);

const ReciptView = lazy(() =>
  import("./pages/Accounts/AccountReceipts/ViewRecipts")
);

const Bankreconciliation = lazy(() =>
  import("./pages/Accounts/BankReconciliation/Bank_Reconciliation")
);
const DailyExpenseReport = lazy(() =>
  import("./pages/Accounts/Reports/daily_expense_report")
);
const CreatePurchase = lazy(() =>
  import("./pages/Accounts/settings/Purchase/purchaseAdd")
);
const Enquiry = lazy(() => import("./pages/CRM/lead/enquiry/enquiry"));
const CreateBillPayment = lazy(() =>
  import("./pages/Accounts/Bill Payments/CreateBillPayment")
);
const ListBillPayment = lazy(() =>
  import("./pages/Accounts/Bill Payments/ListBillPayment")
);
const EditBillPayment = lazy(() =>
  import("./pages/Accounts/Bill Payments/EditBillPayment")
);
const ViewBillPayment = lazy(() =>
  import("./pages/Accounts/Bill Payments/ViewBillPayment")
);
const Fmssettings = lazy(() =>
  import("./pages/General Settings/Fmssettings/fmssetting")
);

// -----------------------reports------------------------
// import PurchaseBillReport from "./pages/FMS/Purchase/Purchase bill report/purchase_bill_report";
const PurchaseBillReport = lazy(() =>
  import("./pages/FMS/Purchase/Purchase bill report/purchase_bill_report")
);

const Quotationterms = lazy(() =>
  import("./pages/General Settings/QutotationTerms/QuotationTerms")
);

const Viewquotationterms = lazy(() =>
  import("./pages/General Settings/QutotationTerms/ViewQuotationTerm")
);

const Quotationtermslist = lazy(() =>
  import("./pages/General Settings/QutotationTerms/QuotationTermList")
);
const ProfitandLoss = lazy(() =>
  import("./pages/Accounts/Reports/ProfitAndLoss/ProfitAndLoss")
);
const TrialBalance = lazy(() =>
  import("./pages/Accounts/Reports/TrialBalance/TrialBalance")
);
const BalanceSheet = lazy(() =>
  import("./pages/Accounts/Reports/BalanceSheet/BalanceSheet")
);

const Openingbalance = lazy(() =>
  import("./pages/Accounts/settings/OpeningBalance/OpeningBalance")
);

const ChartOfAccount = lazy(() =>
  import("./pages/Accounts/Reports/chartOfAccount/ChartOfAccount")
);

// -------------------selling price----------------------------
const Selling_price = lazy(() =>
  import("./pages/General Settings/Selling-price-margin/Selling_price")
);

const DiamentionCalculation = lazy(() =>
  import("./pages/General Settings/DiamentionCalculation/DiamentionCalculation")
);

const ServiceEdit = lazy(() =>
  import("./pages/CRM/Selling/services/ServiceEdit")
);
const Incoterm = lazy(() => import("./pages/FMS/settings/Incoterm/incoterm"));
const EnquiryList = lazy(() => import("./pages/CRM/lead/enquiry/EnquiryList"));
const EditEnquiry = lazy(() => import("./pages/CRM/lead/enquiry/EditEnquiry"));
const ViewEnquiry = lazy(() => import("./pages/CRM/lead/enquiry/ViewEnquiry"));
const Viewvendor = lazy(() => import("./pages/CRM/Purchase/vendor/viewvendor"));
const Containertypes = lazy(() =>
  import("./pages/FMS/settings/containertypes/containertypes")
);
const Cargotype = lazy(() =>
  import("./pages/FMS/settings/CargoType/CargoType")
);
const EnquirySource = lazy(() =>
  import("./pages/CRM/lead/EnquirySource/EnquirySource")
);
const TaxGroup = lazy(() => import("./pages/FMS/settings/TaxGroup/TaxGroup"));
const AwbblReport = lazy(() =>
  import("./pages/FMS/Awb_bl_report/awb_bl_report")
);

const Shipmentstatusreport = lazy(() =>
  import("./pages/FMS/Shipment_StatusReport/ShipmentStatusReoport")
);

const Ledger = lazy(() => import("./pages/Accounts/settings/Ledger/Ledger"));
const Gltypes = lazy(() => import("./pages/Accounts/settings/GLType/gl_type"));
const AccGroup = lazy(() =>
  import("./pages/Accounts/settings/Account Group/AccGroup")
);
// const Invoicetemp1 = lazy(() =>
//   import("./components/Invoice/invoicetemp1/invoicetemp1")
// );
// const Invoicetemplate2 = lazy(() =>
//   import("./components/Invoice/invoicetemp2/invoicetemp2")
// );
const Invoicetemplateselect = lazy(() =>
  import("./pages/General Settings/invoicetemplates/invoicetemplate")
);
const Viewcustomer = lazy(() =>
  import("./pages/CRM/lead/lead_list/view_customer")
);
const ViewOpportunity = lazy(() =>
  import("./pages/CRM/lead/modals/viewopportunity")
);
const ViewService = lazy(() =>
  import("./pages/CRM/Selling/services/ViewService")
);
const AccountSettings = lazy(() =>
  import("./pages/Accounts/settings/AccountSettings/AccountSettings")
);
const Purchasebill = lazy(() =>
  import("./pages/FMS/Purchase/Purchasebill/Purchasebill")
);
const Addpurchasebill = lazy(() =>
  import("./pages/FMS/Purchase/Purchasebill/add_purchasebill")
);
const EditEmployee = lazy(() => import("./pages/HRMS/employees/editEmployee"));
const ViewEmployee = lazy(() => import("./pages/HRMS/employees/viewEmployee"));
const ViewInvoices = lazy(() => import("./pages/FMS/Invoice/ViewInvoices"));
// import AddRfq from "./pages/FMS/RFQ/add_requestQtn";
const AddRfq = lazy(() => import("./pages/CRM/RFQ/add_requestQtn"));
// import RfqList from "./pages/CRM/RFQ/list_rfq";
// import ViewRfq from "./pages/CRM/RFQ/view_rfq";

const ViewRfq = lazy(() => import("./pages/CRM/RFQ/view_rfq"));
const RfqList = lazy(() => import("./pages/CRM/RFQ/list_rfq"));
// import ImcoStatus from "./pages/FMS/settings/Imco Status/imcoStatus";
const ImcoStatus = lazy(() =>
  import("./pages/FMS/settings/Imco Status/imcoStatus")
);
const Clearancedocuments = lazy(() =>
  import("./pages/FMS/settings/clearancedocuments/clearancedocuments")
);

const Timestampheads = lazy(() =>
  import("./pages/FMS/settings/Timestampheads/Timestampheads")
);
const Financialyear = lazy(() =>
  import("./pages/FMS/settings/FinancialYear/FinancialYear")
);

const Createnewjob = lazy(() => import("./pages/FMS/Job/Jobphase2/Jobcreate"));

const ConsolJob = lazy(() => import("./pages/FMS/console Job/ConsolJob"));
const ListconsolJob = lazy(() =>
  import("./pages/FMS/console Job/ConsoljobList")
);
const Editconsoljob = lazy(() => import("./pages/FMS/console Job/ConsolJob"));

const Viewconsoljob = lazy(() =>
  import("./pages/FMS/console Job/Viewconsoljob")
);
// /////accounts

const Receiptdetailsmodal = lazy(() =>
  import("./pages/Accounts/AccountReceipts/receiptdetailmodal")
);

////-------JournalEntry-------////
const AddJournalenrty = lazy(() =>
  import("./pages/Accounts/JournalEntry/addJournalentry")
);
const ViewJournalEntry = lazy(() =>
  import("./pages/Accounts/JournalEntry/ViewJournalEntry")
);
const ListJournalEntry = lazy(() =>
  import("./pages/Accounts/JournalEntry/ListJournalEntry")
);
const AddEditReceipts = lazy(() =>
  import("./pages/Accounts/AccountReceipts/AddEditReceipts")
);
const View_purchasebill = lazy(() =>
  import("./pages/FMS/Purchase/Purchasebill/view_purchasebill")
);
const ListReceipts = lazy(() =>
  import("./pages/Accounts/AccountReceipts/ListReceipts")
);

const DailySalesReport = lazy(() =>
  import("./pages/FMS/DailysalesReport/Dailysalesreport")
);
const CustomerwiseReport = lazy(() =>
  import("./pages/FMS/CustomerWiseReport/CustomerwiseReport")
);

// ------arivalnotice print---------

// const Arrivalnoticeprint = lazy(() =>
//   import("./pages/FMS/Job/ArrivalNoticePrint/Arrivalnoticeprint")
// );

// ------------shipmentstaus print---------
const ShipmentStatusReportPrint = lazy(() =>
  import("./pages/FMS/Shipment_StatusReport/shipmentstatusprint")
);

const ShipmentPendingBookings = lazy(() =>
  import("./pages/FMS/ShipmentPendingBooking/ShipmentPendingBooking")
);

const ShipmentPendingJobs = lazy(() =>
  import("./pages/FMS/ShipmentPendingJobReport/ShipmentPendingJobReport")
);

// const CostandexpensePrint = lazy(()=>
// import("./pages/FMS/CustomerwiseCostAndExpenseReport/costandexpensePrint"))

// const MonthlyreportPrint = lazy(()=>
// import("./pages/FMS/Monthly report/monthlyreportPrint") )

// --------------pending crm--------------

// const Pendingoppo = lazy (() => import("./pages/CRM/lead/opportunity_ List/PendingOpportunity"))
// const Pendingquotation = lazy(() => import("./pages/FMS/Quotations/create quotation/PendingQuotation"))
// reports of ageing

const Ageing = lazy(() =>
  import("./pages/Accounts/Reports/AgeingReport/Ageing")
);
const PendingInvoices = lazy(() =>
  import("./pages/Accounts/Reports/Pending Invoices/pendingInvoices")
);
const Salespersonrevenuereport = lazy(() =>
  import(
    "./pages/Accounts/Reports/Salespersonrevenuereport/Salespersonrevenuereport.jsx"
  )
);

// permission denied
const Permissiondenied = lazy(() => import("./components/error/PermissionD"));

const EmailSchedules = lazy(() =>
  import("./pages/General Settings/Email-Schedules/EmailSchedules")
);

function App() {
  // const navigate = useNavigate();

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // const navigate = useNavigate();
  useEffect(() => {
    // if (!isOnline) {
    //   // navigate(`${ROUTES.PAGE_NOT_FOUND}`);
    //   return <Link to={`${ROUTES.PAGE_NOT_FOUND}`} />;
    // }
  }, [isOnline]);

  // const PreventNavigator = () => {
  //   const navigate = useNavigate()

  // }

  return (
    <div className="App">
      {/* {isOnline ? ( */}
      {/* {unsavedChanges === false && ( */}
      <BrowserRouter>
        {/* <Suspense fallback={<LoadingPage />}> */}

        <Routes>
          <Route path={ROUTES.LOGIN} element={<Login />} />

          <Route path={ROUTES.DASHBOARD_CRM} element={<ProtectedRoute />}>
            <Route index element={<Dashboard />} />
          </Route>
          <Route path={ROUTES.PERMISSIONDEN} element={<ProtectedRoute />}>
            <Route index element={<Permissiondenied />} />
          </Route>
          <Route path={ROUTES.DASHBOARD_PURCHASE} element={<ProtectedRoute />}>
            <Route index element={<Dashboard />} />
          </Route>
          <Route
            path={ROUTES.DASHBOARD_OPERATIONS}
            element={<ProtectedRoute />}
          >
            <Route index element={<Dashboard />} />
          </Route>
          <Route path={ROUTES.DASHBOARD_ACCOUNTS} element={<ProtectedRoute />}>
            <Route index element={<Dashboard />} />
          </Route>

          {/* <Route path={ROUTES.DASHBOARDNEW} element={<ProtectedRoute />}>
              <Route index element={<DashboardNew />} />
            </Route> */}

          <Route path={ROUTES.CRM_TAB} element={<ProtectedRoute />}>
            <Route index element={<Crm_tab />} />
          </Route>

          <Route path={ROUTES.CUSTOMER_ID} element={<ProtectedRoute />}>
            <Route index element={<Convertcustomer />} />
          </Route>

          <Route path={ROUTES.COUNTRYSELECT} element={<ProtectedRoute />}>
            <Route index element={<SelectCountry />} />
          </Route>
          <Route path={ROUTES.CUSTOMER} element={<ProtectedRoute />}>
            <Route index element={<Lead />} />
          </Route>

          <Route path={ROUTES.CUSTOMER_LIST} element={<ProtectedRoute />}>
            <Route index element={<LeadList />} />
          </Route>

          <Route path={ROUTES.CUSTOMER} element={<ProtectedRoute />}>
            <Route index element={<Lead />} />
          </Route>

          <Route path={ROUTES.VIEW_CUSTOMER_ID} element={<ProtectedRoute />}>
            <Route index element={<Viewcustomer />} />
          </Route>

          <Route path={ROUTES.CATEGORY} element={<ProtectedRoute />}>
            <Route index element={<Category />} />
          </Route>

          <Route path={ROUTES.CATEGORY_EDIT_ID} element={<ProtectedRoute />}>
            <Route index element={<CategoryUpdate />} />
          </Route>

          <Route path={ROUTES.TEST_PAGE} element={<ProtectedRoute />}>
            <Route index element={<TestPage />} />
          </Route>
          <Route path={ROUTES.OPPORTUNITY} element={<ProtectedRoute />}>
            <Route index element={<Opportunitylist />} />
          </Route>
          {/* sales - enquiries */}

          <Route path={ROUTES.CREATE_ENQUIRY} element={<ProtectedRoute />}>
            <Route index element={<Enquiry />} />
          </Route>
          <Route path={ROUTES.ENQUIRY_LIST} element={<ProtectedRoute />}>
            <Route index element={<EnquiryList />} />
          </Route>
          <Route path={ROUTES.EDIT_ENQUIRY_ID} element={<ProtectedRoute />}>
            <Route index element={<EditEnquiry />} />
          </Route>
          <Route path={ROUTES.VIEW_ENQUIRY_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewEnquiry />} />
          </Route>
          <Route path={ROUTES.ENQUIRY_SOURCE} element={<ProtectedRoute />}>
            <Route index element={<EnquirySource />} />
          </Route>

          {/* / FMS => MASTER => TAX GROUP */}
          <Route path={ROUTES.TAX_GROUP} element={<ProtectedRoute />}>
            <Route index element={<TaxGroup />} />
          </Route>

          {/* {ACCOUNTS => MASTER => LEDGER } */}
          <Route path={ROUTES.LEDGER} element={<ProtectedRoute />}>
            <Route index element={<Ledger />} />
          </Route>

          <Route path={ROUTES.RECIEPT_DETAILMODAL} element={<ProtectedRoute />}>
            <Route index element={<Receiptdetailsmodal />} />
          </Route>

          {/* {ACCOUNT => MASTER => ACCOUNT GROUP} */}
          <Route path={ROUTES.ACC_GROUP} element={<ProtectedRoute />}>
            <Route index element={<AccGroup />} />
          </Route>

          <Route path={ROUTES.OPPORTUNITY_LEAD_ID} element={<ProtectedRoute />}>
            <Route index element={<OpportunityLeadlist />} />
          </Route>

          <Route path={ROUTES.CATEGORY_LIST} element={<ProtectedRoute />}>
            <Route index element={<Categorylist />} />
          </Route>

          <Route path={ROUTES.LEAD_REPORT} element={<ProtectedRoute />}>
            <Route index element={<LeadReport />} />
          </Route>
          <Route path={ROUTES.AWBBL_REPORT} element={<ProtectedRoute />}>
            <Route index element={<AwbblReport />} />
          </Route>

          <Route path={ROUTES.EMAIL_SCHEDULES} element={<ProtectedRoute />}>
            <Route index element={<EmailSchedules />} />
          </Route>

          <Route
            path={ROUTES.SHIPMENT_STATUS_REPORT}
            element={<ProtectedRoute />}
          >
            <Route index element={<Shipmentstatusreport />} />
          </Route>

          <Route path={ROUTES.LEAD_CUSTOMER_ID} element={<ProtectedRoute />}>
            <Route index element={<LeadEdit />} />
          </Route>

          <Route path={ROUTES.BRANDS} element={<ProtectedRoute />}>
            <Route index element={<BrandsList />} />
          </Route>

          <Route path={ROUTES.ATTRIBUTES} element={<ProtectedRoute />}>
            <Route index element={<Attribute />} />
          </Route>

          <Route path={ROUTES.ADD_ATTRIBUTES} element={<ProtectedRoute />}>
            <Route index element={<Add_Attribute />} />
          </Route>

          <Route path={ROUTES.PENDING_ENQ_ID} element={<ProtectedRoute />}>
            <Route index element={<EnquiryList />} />
          </Route>

          <Route path={ROUTES.PENDING_OPPOR_ID} element={<ProtectedRoute />}>
            <Route index element={<Opportunitylist />} />
          </Route>

          <Route path={ROUTES.PENDING_QTN_ID} element={<ProtectedRoute />}>
            <Route index element={<Quotations />} />
          </Route>

          <Route path={ROUTES.PENDING_RFQ_ID} element={<ProtectedRoute />}>
            <Route index element={<RfqList />} />
          </Route>
          <Route path={ROUTES.PENDING_ORDERS_ID} element={<ProtectedRoute />}>
            <Route index element={<ListpurchaseOrder />} />
          </Route>

          <Route
            path={ROUTES.PENDING_PURCHASE_BILL_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<Purchasebill />} />
          </Route>

          <Route
            path={ROUTES.PENDING_JOB_BOOKING_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<List_job_booking />} />
          </Route>

          <Route path={ROUTES.PENDING_JOB_ID} element={<ProtectedRoute />}>
            <Route index element={<Listjob />} />
          </Route>
          <Route path={ROUTES.PENDING_INV_ID} element={<ProtectedRoute />}>
            <Route index element={<InvoiceList />} />
          </Route>
          <Route path={ROUTES.COMPLETED_JOB_ID} element={<ProtectedRoute />}>
            <Route index element={<Listjob />} />
          </Route>

          <Route path={ROUTES.OPPORTUNITY_REPORT} element={<ProtectedRoute />}>
            <Route index element={<OpportunityReport />} />
          </Route>

          <Route path={ROUTES.VENDOR_TYPE} element={<ProtectedRoute />}>
            <Route index element={<Vendortype />} />
          </Route>
          <Route path={ROUTES.VENDOR} element={<ProtectedRoute />}>
            <Route index element={<Vendor />} />
          </Route>

          {/* <Route path={ROUTES.UNIT_LIST} element={<Unitlist />} /> */}
          {/* <Route path={ROUTES.ADD_UNIT} element={<Addunit />} /> */}

          <Route path={ROUTES.UNIT_LIST} element={<ProtectedRoute />}>
            <Route index element={<Unitlist />} />
          </Route>

          <Route path={ROUTES.ADD_UNIT} element={<ProtectedRoute />}>
            <Route index element={<Addunit />} />
          </Route>

          <Route path={ROUTES.BRANDCREATE} element={<ProtectedRoute />}>
            <Route index element={<BrandCreate />} />
          </Route>

          <Route path={ROUTES.PRODUCT} element={<ProtectedRoute />}>
            <Route index element={<Productlist />} />
          </Route>

          <Route path={ROUTES.PRODUCTCREATE} element={<ProtectedRoute />}>
            <Route index element={<ProductCreate />} />
          </Route>

          <Route path={ROUTES.PRODUCTUPDATE_ID} element={<ProtectedRoute />}>
            <Route index element={<ProductUpdate />} />
          </Route>

          <Route path={ROUTES.PRODUCTDETAIL_ID} element={<ProtectedRoute />}>
            <Route index element={<ProductDetails />} />
          </Route>

          <Route path={ROUTES.PRODUCTVARIENTS_ID} element={<ProtectedRoute />}>
            <Route index element={<Varients />} />
          </Route>

          <Route path={ROUTES.SERVICES} element={<ProtectedRoute />}>
            <Route index element={<Services />} />
          </Route>

          <Route path={ROUTES.SERVICECREATE} element={<ProtectedRoute />}>
            <Route index element={<ServiceCreate />} />
          </Route>

          <Route path={ROUTES.SERVICE_EDIT_ID} element={<ProtectedRoute />}>
            <Route index element={<ServiceEdit />} />
          </Route>
          <Route path={ROUTES.VIEW_SERVICE_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewService />} />
          </Route>

          <Route path={ROUTES.BRANCHES} element={<ProtectedRoute />}>
            <Route index element={<Branches />} />
          </Route>

          <Route path={ROUTES.DEPARTMENTS} element={<ProtectedRoute />}>
            <Route index element={<Departments />} />
          </Route>

          <Route path={ROUTES.DESIGNATION} element={<ProtectedRoute />}>
            <Route index element={<Designation />} />
          </Route>

          <Route path={ROUTES.EMPLOYMENT_TYPE} element={<ProtectedRoute />}>
            <Route index element={<EmploymentType />} />
          </Route>

          <Route path={ROUTES.PERMISSIONS} element={<ProtectedRoute />}>
            <Route index element={<Permission />} />
          </Route>

          <Route path={ROUTES.ROLES_SCREEN} element={<ProtectedRoute />}>
            <Route index element={<Roles_and_Screen />} />
          </Route>

          <Route path={ROUTES.EMPLOYEES} element={<ProtectedRoute />}>
            <Route index element={<Employees />} />
          </Route>

          <Route path={ROUTES.CREATEEMPLOYEE} element={<ProtectedRoute />}>
            <Route index element={<CreateEmployee />} />
          </Route>
          <Route path={ROUTES.EDIT_EMPLOYEE_ID} element={<ProtectedRoute />}>
            <Route index element={<EditEmployee />} />
          </Route>
          <Route path={ROUTES.VIEW_EMPLOYEE_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewEmployee />} />
          </Route>

          <Route path={ROUTES.EMPLOYEEGRADE} element={<ProtectedRoute />}>
            <Route index element={<Employeegrade />} />
          </Route>
          <Route path={ROUTES.VIEW_OPPORTUNITY_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewOpportunity />} />
          </Route>
          <Route path={ROUTES.ADD_OPPORTUNITY} element={<ProtectedRoute />}>
            <Route index element={<AddOpportunity />} />
          </Route>
          <Route path={ROUTES.ADD_OPPORTUNITY_ID} element={<ProtectedRoute />}>
            <Route index element={<AddOpportunity />} />
          </Route>
          <Route path={ROUTES.EDIT_OPPORTUNITY_ID} element={<ProtectedRoute />}>
            <Route index element={<EditOpportunity />} />
          </Route>

          {/* {FMS} */}
          <Route path={ROUTES.TRACK_ASSIGNMENTS} element={<ProtectedRoute />}>
            <Route index element={<Track_assignments />} />
          </Route>

          <Route path={ROUTES.LISTAGENT} element={<ProtectedRoute />}>
            <Route index element={<ListAgent />} />
          </Route>
          {/* purchase */}
          <Route path={ROUTES.PURCHASEORDER} element={<ProtectedRoute />}>
            <Route index element={<Purchaseorder />} />
          </Route>
          <Route path={ROUTES.PUCHASE_ORDER_LIST} element={<ProtectedRoute />}>
            <Route index element={<ListpurchaseOrder />} />
          </Route>
          <Route
            path={ROUTES.EDIT_PUCHASE_ORDER_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<Purchaseorder />} />
          </Route>
          <Route
            path={ROUTES.VIEW_PURCHASE_ORDER_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<ViewpurchaseOrder />} />
          </Route>
          <Route path={ROUTES.PURCHASEBILL} element={<ProtectedRoute />}>
            <Route index element={<Purchasebill />} />
          </Route>
          <Route path={ROUTES.ADD_PURCHASEBILL} element={<ProtectedRoute />}>
            <Route index element={<Addpurchasebill />} />
          </Route>
          <Route
            path={ROUTES.EDIT_PURCHASEBILL_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<Addpurchasebill />} />
          </Route>
          <Route
            path={ROUTES.VIEW_PURCHASEBILL_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<View_purchasebill />} />
          </Route>
          {/* <Route  path={ROUTES.UPDATEAGENT}
              element={<UpdateAgent />} />   */}
          <Route path={ROUTES.UPDATEAGENT_ID} element={<ProtectedRoute />}>
            <Route index element={<UpdateAgent />} />
          </Route>

          <Route
            path={ROUTES.ASSIGN_OPPORTUNITIES_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<Assign_opportunity />} />
          </Route>

          <Route
            path={ROUTES.UPDATE_OPPORTUNITY_ASSIGNMENT}
            element={<ProtectedRoute />}
          >
            <Route index element={<UpdateAssignOppurtunity />} />
          </Route>

          <Route path={ROUTES.CREATEAGENT} element={<ProtectedRoute />}>
            <Route index element={<CreateAgent />} />
          </Route>

          <Route path={ROUTES.ADDVENDOR} element={<ProtectedRoute />}>
            <Route index element={<Addvendor />} />
          </Route>
          <Route path={ROUTES.UPDATE_VENDOR_ID} element={<ProtectedRoute />}>
            <Route index element={<Updatevendor />} />
          </Route>
          <Route path={ROUTES.VIEW_VENDOR_ID} element={<ProtectedRoute />}>
            <Route index element={<Viewvendor />} />
          </Route>

          {/* <Route
              path={ROUTES.FRIGHTLIST}
              element={<Frightlist />}
            /> */}
          <Route path={ROUTES.QUATATIONS} element={<ProtectedRoute />}>
            <Route index element={<Quotations />} />
          </Route>

          <Route path={ROUTES.ADD_QUOTATION} element={<ProtectedRoute />}>
            <Route index element={<Add_Quotation />} />
          </Route>
          <Route path={ROUTES.ADD_QUOTATION_ID} element={<ProtectedRoute />}>
            <Route index element={<Add_Quotation />} />
          </Route>

          <Route path={ROUTES.FRIGHTLIST} element={<ProtectedRoute />}>
            <Route index element={<Frightlist />} />
          </Route>
          <Route path={ROUTES.IMCOSTATUS} element={<ProtectedRoute />}>
            <Route index element={<ImcoStatus />} />
          </Route>

          <Route path={ROUTES.CLEARANCEDOCUMENTS} element={<ProtectedRoute />}>
            <Route index element={<Clearancedocuments />} />
          </Route>

          <Route path={ROUTES.TIMESTAMPHEADS} element={<ProtectedRoute />}>
            <Route index element={<Timestampheads />} />
          </Route>

          <Route path={ROUTES.FINANCIALYEAR} element={<ProtectedRoute />}>
            <Route index element={<Financialyear />} />
          </Route>

          <Route path={ROUTES.CARRIER} element={<ProtectedRoute />}>
            <Route index element={<Carrierlist />} />
          </Route>

          <Route path={ROUTES.MODE} element={<ProtectedRoute />}>
            <Route index element={<Modelist />} />
          </Route>

          <Route path={ROUTES.QUATATIONS} element={<ProtectedRoute />}>
            <Route index element={<Quotations />} />
          </Route>

          <Route path={ROUTES.EDIT_QUOTATION_ID} element={<ProtectedRoute />}>
            <Route index element={<EditQuotation />} />
          </Route>

          <Route path={ROUTES.VIEW_QUOTATION_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewQuotation />} />
          </Route>

          <Route path={ROUTES.ENQUIRIES} element={<ProtectedRoute />}>
            <Route index element={<Enquiries />} />
          </Route>

          <Route path={ROUTES.AGENT_RESPONSE_ID} element={<ProtectedRoute />}>
            <Route index element={<Agent_Response />} />
          </Route>

          <Route
            path={ROUTES.EDIT_ASSIGN_OPPORTUNITY_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<Assign_oppertunity_edit />} />
          </Route>

          <Route path={ROUTES.CONSIGNEE} element={<ProtectedRoute />}>
            <Route index element={<Consignee />} />
          </Route>

          <Route path={ROUTES.TERMS_OF_PAYMENT} element={<ProtectedRoute />}>
            <Route index element={<PaymentTerms />} />
          </Route>

          <Route path={ROUTES.TAXTYPE} element={<ProtectedRoute />}>
            <Route index element={<TaxType />} />
          </Route>

          <Route path={ROUTES.JOBTASKS} element={<ProtectedRoute />}>
            <Route index element={<JobTasks />} />
          </Route>

          <Route path={ROUTES.AIRPORT} element={<ProtectedRoute />}>
            <Route index element={<Airport />} />
          </Route>

          <Route path={ROUTES.SEAPORT} element={<ProtectedRoute />}>
            <Route index element={<Seaport />} />
          </Route>

          <Route path={ROUTES.LOCATIONS} element={<ProtectedRoute />}>
            <Route index element={<Locations />} />
          </Route>
          <Route path={ROUTES.INCOTERM} element={<ProtectedRoute />}>
            <Route index element={<Incoterm />} />
          </Route>

          <Route path={ROUTES.CONTAINER_TYPES} element={<ProtectedRoute />}>
            <Route index element={<Containertypes />} />
          </Route>

          <Route path={ROUTES.CARGO_TYPE} element={<ProtectedRoute />}>
            <Route index element={<Cargotype />} />
          </Route>

          <Route path={ROUTES.ASSIGN_QUOTATION_ID} element={<ProtectedRoute />}>
            <Route index element={<QuotationAssign />} />
          </Route>

          <Route path={ROUTES.LIST_JOB} element={<ProtectedRoute />}>
            <Route index element={<Listjob />} />
          </Route>

          {/* <Route path={ROUTES.CREATEJOB} element={<ProtectedRoute />}>
                <Route index element={<Crea />} />
              </Route> */}

          <Route path={ROUTES.CREATEJOB_ID} element={<ProtectedRoute />}>
            <Route index element={<Createnewjob />} />
          </Route>

          {/* <Route path={ROUTES.UPDATEJOB_ID} element={<ProtectedRoute />}>
                <Route index element={<Updatejob />} />
              </Route> */}

          <Route path={ROUTES.VIEW_JOB_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewJob />} />
          </Route>

          {/* jobphase2 */}
          <Route path={ROUTES.CREATENEWJOB} element={<ProtectedRoute />}>
            <Route index element={<Createnewjob />} />
          </Route>

          <Route path={ROUTES.UPDATE_JOB_ID} element={<ProtectedRoute />}>
            <Route index element={<Updatejob />} />
          </Route>

          <Route path={ROUTES.JOB_CLOSING} element={<ProtectedRoute />}>
            <Route index element={<JobClosing />} />
          </Route>

          {/* <Route path={ROUTES.CONSOLJOB} element={<ProtectedRoute /> }>
          <Route index element={<ConsolJob />} />
          </Route> */}
          <Route path={ROUTES.LIST_CONSOLJOB} element={<ProtectedRoute />}>
            <Route index element={<ListconsolJob />} />
          </Route>

          <Route path={ROUTES.CONSOLJOB} element={<ProtectedRoute />}>
            <Route index element={<ConsolJob />} />
          </Route>

          <Route path={ROUTES.UPDATE_CONSOLJOB_ID} element={<ProtectedRoute />}>
            <Route index element={<Editconsoljob />} />
          </Route>

          <Route path={ROUTES.VIEW_CONSOLJOB_ID} element={<ProtectedRoute />}>
            <Route index element={<Viewconsoljob />} />
          </Route>

          <Route
            path={ROUTES.CONSOL_JOB_TASK_AND_EXPENSE_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<Taskexpenses />} />
          </Route>

          <Route path={ROUTES.INVOICE_LIST} element={<ProtectedRoute />}>
            <Route index element={<InvoiceList />} />
          </Route>

          <Route path={ROUTES.INVOICE_PREVIEW_ID} element={<ProtectedRoute />}>
            <Route index element={<InvoicePreView />} />
          </Route>
          <Route
            path={ROUTES.EDIT_INVOICE_PREVIEW_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<EditInvoicePreview />} />
          </Route>

          <Route
            path={ROUTES.INVOICE_PREVIEW_JOB_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<InvoicePreView />} />
          </Route>
          <Route
            path={ROUTES.CONSOL_JOB_INVOICE_PREVIEW_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<InvoicePreView />} />
          </Route>
          <Route
            path={ROUTES.CONSOL_JOB_INVOICE_PREVIEW_INV_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<InvoicePreView />} />
          </Route>

          {/* Accounts */}
          {/* <Route path={ROUTES.PAYMEMENT_MODE} element={<ProtectedRoute/>}> */}
          {/* <Route index element={<Payment_mode/>}/> */}
          {/* </Route> */}
          <Route path={ROUTES.PAYMEMENT_MODE} element={<ProtectedRoute />}>
            <Route index element={<Payment_mode />} />
          </Route>
          <Route path={ROUTES.CREATE_PURCHASE} element={<ProtectedRoute />}>
            <Route index element={<CreatePurchase />} />
          </Route>

          <Route path={ROUTES.ADD_PURCHASE} element={<ProtectedRoute />}>
            <Route index element={<Add_purchase />} />
          </Route>
          <Route path={ROUTES.VIEW_PURCHASE_ID} element={<ProtectedRoute />}>
            <Route index element={<View_purchase />} />
          </Route>
          <Route path={ROUTES.EDIT_PURCHASE_ID} element={<ProtectedRoute />}>
            <Route index element={<Edit_purchase />} />
          </Route>
          <Route path={ROUTES.PURCHASE} element={<ProtectedRoute />}>
            <Route index element={<Purchase />} />
          </Route>
          <Route path={ROUTES.PRINT_PURCHASE} element={<ProtectedRoute />}>
            <Route index element={<Print_purchase />} />
          </Route>

          <Route path={ROUTES.INVOICE_VIEW_ID} element={<ProtectedRoute />}>
            <Route index element={<InvoiceView />} />
          </Route>

          <Route path={ROUTES.CREDIT_NOTES} element={<ProtectedRoute />}>
            <Route index element={<Credit_notes />} />
          </Route>
          <Route path={ROUTES.LIST_Receipts} element={<ProtectedRoute />}>
            {/* ==========reciepts======= */}
            <Route index element={<ListReceipts />} />
          </Route>
          <Route path={ROUTES.ADD_CREDIT_NOTES} element={<ProtectedRoute />}>
            <Route index element={<Addcredit_notes />} />
          </Route>

          <Route
            path={ROUTES.EDIT_CREDIT_NOTES_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<Addcredit_notes />} />
          </Route>
          <Route
            path={ROUTES.VIEW_CREDIT_NOTES_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<CreditnotesView />} />
          </Route>

          <Route path={ROUTES.DEBIT_NOTES} element={<ProtectedRoute />}>
            <Route index element={<Debit_notes />} />
          </Route>
          <Route path={ROUTES.ADD_DEBIT_NOTES} element={<ProtectedRoute />}>
            <Route index element={<Adddebit_notes />} />
          </Route>

          <Route path={ROUTES.EDIT_DEBIT_NOTES_ID} element={<ProtectedRoute />}>
            <Route index element={<Adddebit_notes />} />
          </Route>
          <Route path={ROUTES.VIEW_DEBIT_NOTES_ID} element={<ProtectedRoute />}>
            <Route index element={<DebitnotesView />} />
          </Route>

          <Route
            path={ROUTES.LIST_SUPPLIER_CREDITNOTES}
            element={<ProtectedRoute />}
          >
            <Route index element={<ListSupplierCreditnotes />} />
          </Route>
          <Route
            path={ROUTES.ADD_SUPPLIER_CREDITNOTES}
            element={<ProtectedRoute />}
          >
            <Route index element={<AddSupplierCreditnotes />} />
          </Route>

          <Route
            path={ROUTES.EDIT_SUPPLIER_CREDITNOTES_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<EditSupplierCreditnotes />} />
          </Route>

          <Route
            path={ROUTES.VIEW_SUPPLIER_CREDITNOTES_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<ViewSupplierCreditnotes />} />
          </Route>

          <Route path={ROUTES.VIEW_RECIPTS_ID} element={<ProtectedRoute />}>
            <Route index element={<ReciptView />} />
          </Route>

          <Route path={ROUTES.BANK_RECONCILIATION} element={<ProtectedRoute />}>
            <Route index element={<Bankreconciliation />} />
          </Route>

          {/* General settings */}

          <Route path={ROUTES.CURRENCY} element={<ProtectedRoute />}>
            <Route index element={<Currency />} />
          </Route>

          <Route path={ROUTES.COMPANYINFO} element={<ProtectedRoute />}>
            <Route index element={<Companyinfo />} />
          </Route>

          <Route path={ROUTES.FMSSETTINGS} element={<ProtectedRoute />}>
            <Route index element={<Fmssettings />} />
          </Route>
          <Route path={ROUTES.SELLINGPRICE} element={<ProtectedRoute />}>
            <Route index element={<Selling_price />} />
          </Route>

          <Route
            path={ROUTES.DIAMENTIONCALCULATION}
            element={<ProtectedRoute />}
          >
            <Route index element={<DiamentionCalculation />} />
          </Route>

          <Route path={ROUTES.ADD_RFQ} element={<ProtectedRoute />}>
            <Route index element={<AddRfq />} />
          </Route>

          <Route path={ROUTES.ADD_RFQ_ID} element={<ProtectedRoute />}>
            <Route index element={<AddRfq />} />
          </Route>
          <Route path={ROUTES.LIST_RFQ} element={<ProtectedRoute />}>
            <Route index element={<RfqList />} />
          </Route>
          <Route path={ROUTES.VIEW_RFQ_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewRfq />} />
          </Route>

          <Route
            path={ROUTES.SELECT_INVOICETEMPLATE}
            element={<ProtectedRoute />}
          >
            <Route index element={<Invoicetemplateselect />} />
          </Route>

          <Route path={ROUTES.AGENT_REPORT} element={<ProtectedRoute />}>
            <Route index element={<AgentReport />} />
          </Route>

          <Route
            path={ROUTES.COST_AND_EXPENSE_REPORT}
            element={<ProtectedRoute />}
          >
            <Route index element={<CostAndExpenseReport />} />
          </Route>

          <Route path={ROUTES.ENQUIRY_REPORT} element={<ProtectedRoute />}>
            <Route index element={<EnquiryReport />} />
          </Route>

          <Route path={ROUTES.TASKANDEXPENSES_ID} element={<ProtectedRoute />}>
            <Route index element={<Taskexpenses />} />
          </Route>

          <Route path={ROUTES.INVOICE_REPORT} element={<ProtectedRoute />}>
            <Route index element={<Invoicereport />} />
          </Route>

          {/* ######## ACCOUNTS ######## */}
          <Route path={ROUTES.DAILY_EXPENSE} element={<ProtectedRoute />}>
            <Route index element={<DailyExpence />} />
          </Route>
          <Route path={ROUTES.CREATE_EXPENSE} element={<ProtectedRoute />}>
            <Route index element={<CreateExpence />} />
          </Route>
          <Route path={ROUTES.EDIT_EXPENSE_ID} element={<ProtectedRoute />}>
            <Route index element={<EditExpence />} />
          </Route>

          <Route path={ROUTES.VIEW_EXPENSE_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewdailyExpense />} />
          </Route>

          <Route path={ROUTES.EXPENSE_CATEGORY} element={<ProtectedRoute />}>
            <Route index element={<ExpenseCategory />} />
          </Route>

          <Route path={ROUTES.PAYMEMENT_MODE} element={<ProtectedRoute />}>
            <Route index element={<Payment_mode />} />
          </Route>
          <Route path={ROUTES.GL_TYPE} element={<ProtectedRoute />}>
            <Route index element={<Gltypes />} />
          </Route>

          <Route path={ROUTES.ADD_PURCHASE} element={<ProtectedRoute />}>
            <Route index element={<Add_purchase />} />
          </Route>
          <Route path={ROUTES.PURCHASE} element={<ProtectedRoute />}>
            <Route index element={<Purchase />} />
          </Route>
          <Route path={ROUTES.PAYMENTS} element={<ProtectedRoute />}>
            <Route index element={<Payments />} />
          </Route>
          <Route path={ROUTES.ADD_PAYMENTS} element={<ProtectedRoute />}>
            <Route index element={<AddPayments />} />
          </Route>

          {/* ---------- paymentedit */}
          <Route path={ROUTES.EDIT_PAYMENT_ID} element={<ProtectedRoute />}>
            <Route index element={<AddPayments />} />
          </Route>

          <Route path={ROUTES.VIEW_PAYMENT} element={<ProtectedRoute />}>
            <Route index element={<ViewPayment />} />
          </Route>
          <Route path={ROUTES.JOB_PAYMENTS} element={<ProtectedRoute />}>
            <Route index element={<JobPayments />} />
          </Route>
          <Route path={ROUTES.ADD_JOBPAYMENT} element={<ProtectedRoute />}>
            <Route index element={<AddJobPayments />} />
          </Route>
          <Route path={ROUTES.EDIT_JOBPAYMENT} element={<ProtectedRoute />}>
            <Route index element={<EditJobPayment />} />
          </Route>

          <Route path={ROUTES.EDIT_JOBPAYMENT_ID} element={<ProtectedRoute />}>
            <Route index element={<EditJobPayment />} />
          </Route>
          <Route path={ROUTES.VIEW_JOBPAYMENT} element={<ProtectedRoute />}>
            <Route index element={<ViewJobPayment />} />
          </Route>
          <Route path={ROUTES.VIEW_JOBPAYMENT_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewJobPayment />} />
          </Route>
          <Route path={ROUTES.ADD_JOURNAL_ENTRY} element={<ProtectedRoute />}>
            <Route index element={<AddJournalenrty />} />
          </Route>
          <Route path={ROUTES.ADD_JOB_BOOKING} element={<ProtectedRoute />}>
            <Route index element={<Add_Job_Booking />} />
          </Route>
          <Route path={ROUTES.ADD_JOB_BOOKING_ID} element={<ProtectedRoute />}>
            <Route index element={<Add_Job_Booking />} />
          </Route>
          <Route path={ROUTES.LIST_JOB_BOOKING} element={<ProtectedRoute />}>
            <Route index element={<List_job_booking />} />
          </Route>
          <Route path={ROUTES.EDIT_JOB_BOOKING_ID} element={<ProtectedRoute />}>
            <Route index element={<Add_Job_Booking />} />
          </Route>
          <Route path={ROUTES.VIEW_JOB_BOOKING_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewJobBooking />} />
          </Route>

          <Route path={ROUTES.MONTHLY_REPORT} element={<ProtectedRoute />}>
            <Route index element={<Monthly_report />} />
          </Route>
          <Route path={ROUTES.CREDIT_NOTE_TYPE} element={<ProtectedRoute />}>
            <Route index element={<CreditNoteType />} />
          </Route>
          <Route path={ROUTES.BANK_DETAILS} element={<ProtectedRoute />}>
            <Route index element={<Bank />} />
          </Route>
          <Route
            path={ROUTES.DAILY_EXPENSE_REPORT}
            element={<ProtectedRoute />}
          >
            <Route index element={<DailyExpenseReport />} />
          </Route>
          <Route path={ROUTES.CREATE_BILL_PAYMENT} element={<ProtectedRoute />}>
            <Route index element={<CreateBillPayment />} />
          </Route>
          <Route path={ROUTES.BILL_PAYMENT_LIST} element={<ProtectedRoute />}>
            <Route index element={<ListBillPayment />} />
          </Route>
          <Route
            path={ROUTES.EDIT_BILL_PAYMENT_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<EditBillPayment />} />
          </Route>
          <Route
            path={ROUTES.VIEW_BILL_PAYMENT_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<ViewBillPayment />} />
          </Route>
          <Route path={ROUTES.ACCOUNTS_SETTINGS} element={<ProtectedRoute />}>
            <Route index element={<AccountSettings />} />
          </Route>

          <Route path={ROUTES.VIEW_INVOICES_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewInvoices />} />
          </Route>
          <Route path={ROUTES.UPDATE_RFQ_ID} element={<ProtectedRoute />}>
            <Route index element={<AddRfq />} />
          </Route>

          <Route path={ROUTES.CLONE_RFQ_ID} element={<ProtectedRoute />}>
            <Route index element={<AddRfq />} />
          </Route>
          {/* Accounts => RECIPTS */}
          <Route path={ROUTES.ADD_RECEIPT} element={<ProtectedRoute />}>
            <Route index element={<AddEditReceipts />} />
          </Route>
          <Route path={ROUTES.ADD_RECEIPT_ID} element={<ProtectedRoute />}>
            <Route index element={<AddEditReceipts />} />
          </Route>
          <Route path={ROUTES.EDIT_RECEIPT_ID} element={<ProtectedRoute />}>
            <Route index element={<AddEditReceipts />} />
          </Route>

          {/* { Account Journal Entry } */}
          <Route path={ROUTES.LIST_JOURNAL_ENTRY} element={<ProtectedRoute />}>
            <Route index element={<ListJournalEntry />} />
          </Route>
          <Route
            path={ROUTES.EDIT_JOURNAL_ENTRY_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<AddJournalenrty />} />
          </Route>
          <Route
            path={ROUTES.VIEW_JOURNAL_ENTRY_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<ViewJournalEntry />} />
          </Route>

          {/* permission denied  */}
          <Route path={ROUTES.DENIED_PERMISSION} element={<IsDeniedperm />} />

          {/* ///========= Reports Starts =======================/// */}
          <Route
            path={ROUTES.PURCHASE_BILL_REPORT}
            element={<ProtectedRoute />}
          >
            <Route index element={<PurchaseBillReport />} />
          </Route>

          <Route path={ROUTES.QUOTATION_TERMS} element={<ProtectedRoute />}>
            <Route index element={<Quotationterms />} />
          </Route>

          <Route path={ROUTES.VIEW_QTN_TERM_ID} element={<ProtectedRoute />}>
            <Route index element={<Viewquotationterms />} />
          </Route>

          <Route path={ROUTES.QUOTATION_TERMS_ID} element={<ProtectedRoute />}>
            <Route index element={<Quotationterms />} />
          </Route>

          <Route
            path={ROUTES.QUOTATION_TERMS_LIST}
            element={<ProtectedRoute />}
          >
            <Route index element={<Quotationtermslist />} />
          </Route>

          <Route
            path={ROUTES.STATEMENT_OF_ACCOUNTS}
            element={<ProtectedRoute />}
          >
            <Route index element={<StatementOfAccounts />} />
          </Route>

          <Route path={ROUTES.PROFIT_AND_LOSS} element={<ProtectedRoute />}>
            <Route index element={<ProfitandLoss />} />
          </Route>

          <Route
            path={ROUTES.SALES_PERSON_REVENUE_REPORT}
            element={<ProtectedRoute />}
          >
            <Route index element={<Salespersonrevenuereport />} />
          </Route>

          <Route path={ROUTES.REFUND_HISTORY} element={<ProtectedRoute />}>
            <Route index element={<RefundHistory />} />
          </Route>

          <Route path={ROUTES.AGEING} element={<ProtectedRoute />}>
            <Route index element={<Ageing />} />
          </Route>

          <Route path={ROUTES.PENDING_INVOICES} element={<ProtectedRoute />}>
            <Route index element={<PendingInvoices />} />
          </Route>
          <Route path={ROUTES.TRIAL_BALANCE} element={<ProtectedRoute />}>
            <Route index element={<TrialBalance />} />
          </Route>

          <Route path={ROUTES.BALANCE_SHEET} element={<ProtectedRoute />}>
            <Route index element={<BalanceSheet />} />
          </Route>

          <Route path={ROUTES.OPENING_BALANCE} element={<ProtectedRoute />}>
            <Route index element={<Openingbalance />} />
          </Route>

          <Route path={ROUTES.CHART_OF_ACCOUNT} element={<ProtectedRoute />}>
            <Route index element={<ChartOfAccount />} />
          </Route>

          <Route path={ROUTES.DAILY_SALES_REPORT} element={<ProtectedRoute />}>
            <Route index element={<DailySalesReport />} />
          </Route>
          <Route path={ROUTES.CUSTOMERWISE_REPORT} element={<ProtectedRoute />}>
            <Route index element={<CustomerwiseReport />} />
          </Route>

          <Route
            path={ROUTES.SHIPMENT_PENDING_BOOKINGS}
            element={<ProtectedRoute />}
          >
            <Route index element={<ShipmentPendingBookings />} />
          </Route>
          <Route path={ROUTES.DAILY_SALES_REPORT} element={<ProtectedRoute />}>
            <Route index element={<DailySalesReport />} />
          </Route>
          <Route
            path={ROUTES.SHIPMENT_PENDING_BOOKINGS}
            element={<ProtectedRoute />}
          >
            <Route index element={<ShipmentPendingBookings />} />
          </Route>

          <Route
            path={ROUTES.SHIPMENT_PENDING_JOBS}
            element={<ProtectedRoute />}
          >
            <Route index element={<ShipmentPendingJobs />} />
          </Route>

          {/* /******New CRM LEAD*******/}
          <Route path={ROUTES.CRM_LEADLIST} element={<ProtectedRoute />}>
            <Route index element={<NewLeadList />} />
          </Route>
          <Route path={ROUTES.CRM_ADDLEAD} element={<ProtectedRoute />}>
            <Route index element={<AddEditLead />} />
          </Route>
          <Route path={ROUTES.CRM_EDITLEAD_ID} element={<ProtectedRoute />}>
            <Route index element={<AddEditLead />} />
          </Route>
          <Route path={ROUTES.CRM_VIEWLEAD_ID} element={<ProtectedRoute />}>
            <Route index element={<ViewLead />} />
          </Route>

          <Route
            path={ROUTES.ADVANCE_PAYMENT_LIST}
            element={<ProtectedRoute />}
          >
            <Route index element={<AdvancePaymentList />} />
          </Route>
          <Route path={ROUTES.ADD_ADVANCE_PAYMENT} element={<ProtectedRoute />}>
            <Route index element={<AddEditAdvancePayment />} />
          </Route>
          <Route
            path={ROUTES.ADD_ADVANCE_PAYMENT_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<AddEditAdvancePayment />} />
          </Route>
          <Route
            path={ROUTES.EDIT_ADVANCE_PAYMENT_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<AddEditAdvancePayment />} />
          </Route>
          <Route
            path={ROUTES.VIEW_ADVANCE_PAYMENT_ID}
            element={<ProtectedRoute />}
          >
            <Route index element={<ViewAdvancePayment />} />
          </Route>

          {/* </Route> */}

          {/* </Suspense> */}
          {/* </Route> */}

          {/* // Outside the The layout */}

          {/* <Route
            path={ROUTES.PURCHASEORDER_INVOICE_ID}
            element={<ProtectedRoute />}
          > */}
          <Route
            path={ROUTES.PURCHASEORDER_INVOICE_ID}
            element={<Purchaseorderinvoice />}
          />
          {/* </Route> */}
          <Route
            path={ROUTES.QUATATION_INVOICE_ID}
            element={<Quotationinvoice />}
          />
          {/* <Route path={ROUTES.PRINT_INVOICE_ID} element={<ProtectedRoute />}> */}
          <Route path={ROUTES.PRINT_INVOICE_ID} element={<PrintInvoice />} />
          {/* </Route> */}

          {/* print arrival notice */}

          {/* <Route
              path={ROUTES.PRINT_ARRIVALNOTICE_ID}
              element={<ProtectedRoute />}
            >
              <Route
                path={ROUTES.PRINT_ARRIVALNOTICE_ID}
                element={<Arrivalnoticeprint />}
              />
            </Route> */}
          <Route
            path={ROUTES.PRINT_ARRIVALNOTICE_ID}
            element={<Arrivalnoticeprint />}
          />

          <Route path={ROUTES.JOB_INVOICE_ID} element={<Jobinvoice />} />
          <Route path={ROUTES.INVOICE_PRINT} element={<InvoicePrint />} />

          <Route path={ROUTES.INVOICETEMPLATE} element={<Invoicetemp1 />} />
          <Route path={ROUTES.INVOICE_NEWTEMP} element={<Invoicetemplate2 />} />
          <Route
            path={ROUTES.INVOICE_TEMPLATE3}
            element={<Invoicetemplate3 />}
          />

          <Route
            path={ROUTES.PRINT_PURCHASE_BILL_ID}
            element={<PrintPurchaseBill />}
          />
          {/* <Route path={ROUTES.PRINT_ARRIVALNOTICE_ID} element={<Arrivalnoticeprint/> } /> */}

          <Route path={ROUTES.PRINTPAGE} element={<Printdata />} />
          <Route path={ROUTES.PRINT_RECEIPT_ID} element={<ReceiptPrint />} />

          {/* -------- Report-print pages ----------
            
            shippment status report */}
          <Route
            path={ROUTES.PRINT_SHIPMENTSTATUS_REPORT}
            element={<ShipmentStatusReportPrint />}
          />
          <Route
            path={ROUTES.PRINT_SHIPMENT_PENDING_BOOKINGS}
            element={<PendingBookingPrint />}
          />

          <Route
            path={ROUTES.PRINT_SHIPMENT_PENDING_JOBS}
            element={<PendingJobPrint />}
          />

          <Route
            path={ROUTES.PRINT_COSTANDEXPENSE_REPORT}
            element={<CostandexpensePrint />}
          />

          <Route
            path={ROUTES.PRINT_MONTHLY_REPORT}
            element={<MonthlyreportPrint />}
          />
          <Route
            path={ROUTES.PRINT_INVOICE_REPORT}
            element={<InvoicereportPrint />}
          />
          <Route
            path={ROUTES.PRINT_DAILYSALES_REPORT}
            element={<DailysalesreportPrint />}
          />
          <Route
            path={ROUTES.PRINT_AGENT_REPORT}
            element={<AgentreportPrint />}
          />

          <Route
            path={ROUTES.PRINT_AWBBL_REPORT}
            element={<AwbBlReportPrint />}
          />

          <Route
            path={ROUTES.PRINT_PURCHASE_BILLREPORT}
            element={<PurchasebillreportPrint />}
          />
          <Route
            path={ROUTES.PRINT_STATEMENT_OF_ACCOUNTS}
            element={<StatementofAccountsPrint />}
          />
          <Route
            path={ROUTES.PRINT_PENDING_INVOICES}
            element={<PendingInvoicesPrint />}
          />
          <Route
            path={ROUTES.PRINT_AGEING_REPORT}
            element={<AgeingreportPrint />}
          />

          <Route
            path={ROUTES.PRINT_TRIAL_BALANCE}
            element={<TrialbalancePrint />}
          />
          <Route
            path={ROUTES.PRINT_CUSTOMERWISE_REPORT}
            element={<CustomerwiseReportPrint />}
          />

          <Route
            path={ROUTES.PRINT_SUPPLIERCREDITNOTE_ID}
            element={<SupplierCreditPrint />}
          />

          <Route
            path={ROUTES.PRINT_CREDIT_NOTES_ID}
            element={<CreditNotePrint />}
          />
          <Route
            path={ROUTES.PRINT_DEBIT_NOTES_ID}
            element={<DebitNotePrint />}
          />

          <Route
            path={ROUTES.PRINT_INVOICE_TEMP4}
            element={<InvoicePrintTemp4 />}
          />

          <Route
            path={ROUTES.PRINT_INVOICE_TEMP5}
            element={<InvoicePrintTemp5 />}
          />
          <Route
            path={ROUTES.PRINT_TASKEXPENCE_ID}
            element={<Print_taskepence />}
          />

          <Route path={ROUTES.PRINT_PAYMENT_ID} element={<PaymentPrint />} />

          <Route
            path={ROUTES.PRINT_DELIVERYNOTE_ID}
            element={<PrintDeliveryNote />}
          />

          {/* ..............Customer Login and its Customer dashboard.................. */}

          <Route path={ROUTES.CUSTOMER_LOGIN} element={<CustomerLogin />} />
          <Route
            path={ROUTES.CUSTOMER_DASHBOARD}
            element={<CustomerProtectedRoute />}
          >
            <Route index element={<CustomerDashboard />} />
          </Route>
          <Route
            path={ROUTES.CUSTOMER_JOBS}
            element={<CustomerProtectedRoute />}
          >
            <Route index element={<CustomerJobs />} />
          </Route>
          <Route
            path={ROUTES.CUSTOMER_INVOICES}
            element={<CustomerProtectedRoute />}
          >
            <Route index element={<CustomerInvoices />} />
          </Route>
          {/* "..............Customer Ends............................................" */}

          <Route path={"*"} element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      {/* )} */}
      <Custom_model
        Internet={true}
        show={!isOnline}
        onHide={() => setIsOnline(!isOnline)}
      />
    </div>
  );
}

export default App;
