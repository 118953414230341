import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import PublicFetch from "../../../utils/PublicFetch";
import {
  CRM_BASE_URL_FMS,
  CRM_BASE_URL_HRMS,
  GENERAL_SETTING_BASE_URL,
} from "../../../api/bootapi";
import logo from "../../../assets/logo/cargoplusnew.png";
import { useEffect, useState } from "react";
import { camelize } from "../../../utils/camelcaseconvert";
import moment from "moment";

const ArrivalNoticePdf = ({ invoiceData, bankDetails }) => {
  var converter = require("number-to-words");
  const personName = localStorage.getItem("UserID");
  const [empname, setEmpname] = useState();
  const [companyInfo, setcmpnyupdate] = useState();
  const [GrandTotal, setGrandTotal] = useState();
  const [amounts,setAmounts]=useState();
  console.log("personName of loged in", empname);
  console.log("ppppp",invoiceData)
  useEffect(() => {
    if (personName) {
      getOneEmployee();
    }
  }, [personName]);

  const getOneEmployee = () => {
    PublicFetch.get(`${CRM_BASE_URL_HRMS}/employees/${personName}`)
      .then((res) => {
        console.log("Response of employee", res);
        if (res.data.success) {
          console.log("Success of employee", res.data.data);
          //   setTotalcount(res?.data?.data?.totalCount);
          //   setstartcount(res?.data?.data?.startIndex);

          setEmpname(res?.data?.data?.employee_name);
          //   console.log(" newww array data ::", array);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const getallcmpny = async () => {
    try {
      const allcmpny = await PublicFetch.get(
        `${GENERAL_SETTING_BASE_URL}/company`
      );
      // console.log("getting all cmpny", allcmpny);
      console.log(" all cmpny", allcmpny.data.data);
      let a = allcmpny?.data?.data;
      setcmpnyupdate(a[0]);
      console.log("dataa", allcmpny.data.data.length);
      let arry = [];
      allcmpny.data.data.forEach((i, indx) => {
        arry.push(i);
      });
      console.log("all cmpny are", arry);
      // setcmpnyupdate(arry)

      // setallseaports(allseaports.data.data)
    } catch (err) {
      console.log("error to fetching  compnyinfo", err);
    }
  };

  useEffect(() => {
    getallcmpny();
  }, []);

  // grand Total cal
  useEffect(() => {
    let grandTotal = 0;
    let amounts=0;
    invoiceData?.fms_v1_job_task_expenses?.map((item, index) => {
      console.log("ittt",item);
      grandTotal += item?.job_task_expense_cost_subtotalfx;
      amounts+=item?.job_task_expense_cost_subtotal_lx;
    });
    setGrandTotal(grandTotal);
    setAmounts(amounts);
  }, [invoiceData]);

  console.log("user id of employee log in ", personName);
  const styles = StyleSheet.create({
    main: {
      margin: 20,
      border: "1px solid black",
      height: "95%",
    },
    header: {
      flexDirection: "row",
      justifyContent: "center",
    },
    header_text: {
      fontSize: 20,

      // marginTop: 20,
      textDecoration: "underline",
    },
    subHeader_text: {
      fontSize: 15,
      // marginTop: 40,
      // textDecoration: "underline",
      // borderRight: "1px solid black",
    },
    page: {
      //   border: "3px solid blue",
      flexDirection: "column",
    },
    headerRfq: {
      // marginLeft: 5,

      flexDirection: "row",
      // justifyContent: "",
      // borderTop: "1px solid black",
      // borderBottom: "1px solid black",
      // padding: "10px",
    },
    headerRfq_left: {
      gap: 8,
      width: "50%",
      fontSize: 12,
      flexDirection: "column",
      alignContent: "flex-start",
      borderRight: "1px solid black",
      borderTop: "1px solid black",
      // borderBottom: "1px solid black",
      padding: "10px",
    },
    headerRfq_right: {
      gap: 8,
      width: "50%",
      fontSize: 12,
      flexDirection: "column",
      alignContent: "flex-start",
      // borderLeft: "1px solid black",
      padding: "10px",
      borderTop: "1px solid black",
      // borderBottom: "1px solid black",
    },
    tasks: {
      fontSize: 12,
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 8,
      border: "1px solid black",
      width: "100%",
    },
    footer: {
      marginTop: 70,
      flexDirection: "row",
      justifyContent: "space-around",
      fontSize: 13,
    },
    footerName: {
      fontSize: 12,
      marginTop: 20,
      flexDirection: "row",
      justifyContent: "space-around",
    },
    imageView: {
      marginTop: 10,
      flexDirection: "row",
      justifyContent: "center",
      borderBottom: "1px solid black",
      padding: "1%",
    },
    imageView1: {
      marginTop: 10,
      flexDirection: "row",
      justifyContent: "center",
    },
    image: {
      height: 100,
      width: 1000,
    },
    header_style: {
      fontSize: 12,
      marginTop: 20,
      flexDirection: "row",
      justifyContent: "space-around",
      // borderBottom: "1px solid black",
    },
    CompInfo: {
      justifyContent: "start",
      textAlign: "left",
    },

    comp_header_sub_text: {
      fontSize: 16,
      color: "#f3a415",
      marginBottom: 10,
    },
    header_part3: {
      width: "100%",
      border: "1px solid black",
      backgroundColor: "#e6e6e6",
    },
    sub_heading: {
      fontSize: "12px",
      padding: "2%",
    },
    header_part4: {
      width: "100%",
      border: "1px solid black",
      backgroundColor: "#e6e6e6",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 8,
      // padding: "2%",
    },
    tableCels: {
      // borderBottom: "1px solid black",
      padding: "2%",
      borderRight: "1px solid black",
    },
    tableCels1: {
      // borderBottom: "1px solid black",
      padding: "2%",
      width: "100px",
      borderRight: "1px solid black",
    },
    amtWords: {
      flexDirection: "row",
      justifyContent: "flex-start",
      border: "1px solid black",
      padding: "2px",
    },
    innerAmtStyle1: {
      width: "50%",
      // flexDirection: "row",
      // justifyContent: "flex-start",
      // gap: 8,
      borderRight: "1px solid black",
      padding: "2%",
    },
    innerAmtStyle: {
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 8,
      padding: "2%",
      width: "50%",
    },
    amtFontsSize: {
      fontSize: 12,
      fontWeight: 600,
      fontStyle: "bold",
    },
    totalinWords: {
      fontSize: 10,
    },
    totalinWordst: {
      fontSize: 10,
      paddingBottom: "5px",
    },
    innerStyleofAmt: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    tableCels1: {
      padding: "2%",
      borderRight: "1px solid black",
      justifyContent: "flex-start",
      flexDirection: "row",
      textAlign: "left",
    },
    tableCels11: {
      padding: "2%",
    },
    subHeader_text1: {
      fontSize: 15,
      flexDirection: "row",
      justifyContent: "flex-start ",
      textAlign: "left",
    },
    bnkdetails: {
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: 8,
      padding: "1%",
    },
    bankDet: {
      padding: "2%",
    },
    newRow: {
      marginTop: 10,
      flexDirection: "row",
      justifyContent: "flex-start ",
    },
    tableCol: {
      width: "50%",
      padding: "1%",
    },
    heading11: {
      fontSize: 12,

      padding: "2%",
    },
    subValue: {
      fontSize: 12,
      padding: "2%",
      fontWeight: "800",
    },
  });

  return (
    invoiceData && (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.main}>
            <View style={styles.header_style}>
              <View style={styles.imageView}>
                <Image
                  style={styles.image}
                  src={`${process.env.REACT_APP_BASE_URL}/${companyInfo?.company_quotation_header}`}
                />
              </View>
            </View>
            <View style={styles.imageView1}>
              <Text style={styles.header_text}>CARGO ARRIVAL NOTICE</Text>
            </View>
            <View style={styles.newRow}>
              <View style={styles.tableCol}>
                <Text style={styles.heading11}>
                  Customer Name &nbsp;&nbsp;&nbsp;:
                </Text>
                <Text style={styles.subValue}>
                  {invoiceData?.crm_v1_customer?.customer_name}
                </Text>
                <Text style={styles.subValue}>
                  {invoiceData?.crm_v1_customer?.customer_address}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.subValue}>
                  Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;{" "}
                  {moment(invoiceData?.job_date).format("DD-MM-YYYY")}
                </Text>
                <Text style={styles.subValue}>
                  Job Number &nbsp;&nbsp;: &nbsp;&nbsp;{" "}
                  {invoiceData?.job_number}
                </Text>
              </View>
            </View>
            <View style={styles.headerRfq}>
              <View style={styles.headerRfq_left}>
                <Text>Consignee</Text>
                <Text>{invoiceData?.job_consignee}</Text>
                <Text>{invoiceData?.job_consignee_address}</Text>
              </View>
              <View style={styles.headerRfq_right}>
                <Text>Shipper</Text>
                <Text>{invoiceData?.job_shipper}</Text>
                <Text>{invoiceData?.job_shipper_address}</Text>
              </View>
            </View>
            <View style={styles.headerRfq}>
              <View style={styles.headerRfq_left}>
                <Text style={styles.headerRfq_sub_text}>
                  MAWB/MBL &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;{" "}
                  {invoiceData?.job_awb_bl_no}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  HAWB/HBL &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                  {invoiceData?.job_hawb_hbl_no}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  ETA &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                  {moment(invoiceData?.job_eta).format("DD-MM-YYYY")}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Origin &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                  {
                    invoiceData
                      ?.fms_v1_locations_fms_v1_jobs_job_origin_idTofms_v1_locations
                      ?.location_name
                  }
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Destination &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;{" "}
                  {
                    invoiceData
                      ?.fms_v1_locations_fms_v1_jobs_job_destination_idTofms_v1_locations
                      ?.location_name
                  }
                </Text>
                {/* <Text style={styles.headerRfq_sub_text}>
                  Chargeable Weight : {invoiceData?.rfq_chargeable_wt}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Cargo Description &nbsp;&nbsp;:{" "}
                  {invoiceData?.crm_v1_opportunities?.opportunity_cargo_desc}
                </Text> */}
              </View>
              <View style={styles.headerRfq_right}>
                <Text style={styles.headerRfq_sub_text}>
                  No: of Packages &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                  {invoiceData.job_no_of_pieces}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Gross Weight &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                  {invoiceData?.job_gross_wt}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Chargable Weight &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                  {invoiceData?.job_chargeable_wt}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Carrier &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                  {invoiceData?.fms_v1_carrier?.carrier_name}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Mode &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                  {invoiceData?.fms_v1_jobs?.job_shipper}
                </Text>
                <Text style={styles.headerRfq_sub_text}>
                  Shipment Ref no &nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                  {invoiceData?.job_shipment_ref_no}
                </Text>
              </View>
            </View>

            <View style={styles.header_part4}>
              <View style={styles.tableCels11}>
                <Text style={styles.subHeader_text}>#</Text>
              </View>
              <View style={styles.tableCels11}>
                <Text style={styles.subHeader_text1}>Task and Description</Text>
              </View>
              <View style={styles.tableCels11}>
                <Text style={styles.subHeader_text}>Amount</Text>
              </View>
            </View>

            {invoiceData?.fms_v1_job_task_expenses?.map((item, index) => (
              <>
                <View style={styles.tasks}>
                  <View style={styles.tableCels}>
                    <Text style={styles.tasks_text}>{`${index + 1}`}</Text>
                  </View>
                  <View style={styles.tableCels1}>
                    <Text style={styles.tasks_text}>
                      {item?.crm_v1_services?.service_name}{" "}
                    </Text>
                  </View>
                  <View style={styles.tableCels1}>
                    <Text style={styles.tasks_text}>
                      {item?.job_task_expense_cost_subtotal_lx?.toFixed(
                        companyInfo?.number_of_decimal
                      )}
                    </Text>
                  </View>
                </View>
              </>
            ))}

            <View style={styles.amtWords}>
              <View style={styles.innerAmtStyle1}>
                <Text style={styles.totalinWordst}>Total in Words</Text>
                <View>
                  <Text style={styles.amtFontsSize}>
                    KWD : 
                    {amounts && camelize(converter.toWords(amounts))}{" "}
                  </Text>
                </View>
              </View>
              <View style={styles.innerAmtStyle}>
                <View>
                  <Text style={styles.amtFontsSize}>Total</Text>
                </View>
                <View>
                  <Text style={styles.amtFontsSize}>
                    {
                      invoiceData?.fms_v1_jobs?.generalsettings_v1_currency
                        ?.currency_code
                    }{" "}
                    {amounts?.toFixed(companyInfo?.number_of_decimal)}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.bankDet}>
              <Text style={styles.totalinWords}>Thanks For your Business</Text>
            </View>
            <View style={styles.bankDet}>
              <View>
                <Text style={styles.totalinWords}>Bank Details</Text>
              </View>
              <View style={styles?.bnkdetails}>
                <Text style={styles.totalinWords}>
                  Company name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                </Text>

                <Text style={styles.totalinWords}>
                  {companyInfo?.company_name}
                </Text>
              </View>
              <View style={styles?.bnkdetails}>
                <Text style={styles.totalinWords}>
                  Bank name
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                </Text>{" "}
                <Text style={styles.totalinWords}>
                  {bankDetails?.bank_name}
                </Text>
              </View>
              <View style={styles?.bnkdetails}>
                <Text style={styles.totalinWords}>
                  Branch
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                </Text>{" "}
                <Text style={styles.totalinWords}>
                  {bankDetails?.bank_branch}
                </Text>
              </View>
              <View style={styles?.bnkdetails}>
                <Text style={styles.totalinWords}>
                  Account No.
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                </Text>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                <Text style={styles.totalinWords}>
                  {bankDetails?.bank_account_number}
                </Text>
              </View>
              <View style={styles?.bnkdetails}>
                <Text style={styles.totalinWords}>
                  IBAN No
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                </Text>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                <Text style={styles.totalinWords}>
                  {bankDetails?.bank_iban_no}
                </Text>
              </View>
            </View>
            <View></View>
            {/* <View style={styles.footer}>
              <Text style={styles.tasks_text}>Prepared By</Text>
              <Text style={styles.tasks_text}>Approved By</Text>
            </View>
            <View style={styles.footerName}>
              <Text style={styles.tasks_text}>{empname}</Text>
              <Text style={styles.tasks_text}>{empname}</Text>
            </View> */}
          </View>
        </Page>
      </Document>
    )
  );
};

export default ArrivalNoticePdf;
