const ROUTES = {
  LOGIN: "/",
  LAYOUT: "/layout",
  SIDEBAR: "/sidebar",
  DASHBOARD_CRM: "/dashboard/crm",
  DASHBOARD_PURCHASE: "/dashboard/purchase",
  DASHBOARD_OPERATIONS: "/dashboard/operations",
  DASHBOARD_ACCOUNTS: "/dashboard/accounts",
  CUSTOMER_LOGIN: "/customer/login",
  CUSTOMER_DASHBOARD: "/customer/dashboard",
  CUSTOMER_JOBS: "/customer/jobs",
  CUSTOMER_INVOICES: "/customer/invoices",
  DASHBOARDNEW: "/dashboardnew",

  CUSTOMER: "/crm/customer_list/new_customer",
  CUSTOMER_ID: "/crm/customer_list/new_customer/:id",

  CUSTOMER_LIST: "/crm/customer_list",
  CATEGORY: "/masters/category",
  TEST_PAGE: "/test-page",
  OPPORTUNITY: "/crm/opportunity",
  VIEW_OPPORTUNITY: "/crm/opportunity/view_opportunity",
  VIEW_OPPORTUNITY_ID: "/crm/opportunity/view_opportunity/:id",

  CATEGORY_LIST: "/masters/categorylist",

  CATEGORY_EDIT: "/masters/categorylist/category_edit",
  CATEGORY_EDIT_ID: "/masters/categorylist/category_edit/:id",

  LEAD_REPORT: "/crm/customer_list/Customer_Report",
  CUSTOMER_EDIT: "/crm/customer_list/edit_customer_list",

  VIEW_CUSTOMER: "/crm/customer_list/view_customer",
  VIEW_CUSTOMER_ID: "/crm/customer_list/view_customer/:id",

  OPPORTUNITY_REPORT: "/crm/opportunity/opportunity-report",
  OPPORTUNITY_LEAD_ID: "/crm/opportunity/opportunity_lead/:id",
  OPPORTUNITY_LEAD: "/crm/opportunity/opportunity_lead",

  LEAD_CUSTOMER_ID: "/crm/customer_list/edit_customer_list/:id",
  COUNTRYSELECT: "/settings/CountrySelect",
  BRANDS: "/masters/brands",
  ATTRIBUTES: "/masters/attributes",
  ADD_ATTRIBUTES: "/masters/attributes/add_attribute",
  UNIT_LIST: "/masters/unit-list",

  ADD_UNIT: "/masters/unit-list/add-unit",
  ADD_OPPORTUNITY: "/crm/opportunity/add-opportunity",
  ADD_OPPORTUNITY_ID: "/crm/opportunity/add-opportunity/:id",
  EDIT_OPPORTUNITY: "/crm/opportunity/edit-opportunity",
  EDIT_OPPORTUNITY_ID: "/crm/opportunity/edit-opportunity/:id",
  BRANDCREATE: "/masters/createbrand",
  PRODUCT: "/product",
  PRODUCTCREATE: "/product/create_product",

  PRODUCTUPDATE: "/product/update_product",
  PRODUCTUPDATE_ID: "/product/update_product/:id",

  PRODUCTDETAIL: "/product/product_details",
  PRODUCTDETAIL_ID: "/product/product_details/:id",

  PRODUCTVARIENTS: "/product/product_varients",
  PRODUCTVARIENTS_ID: "/product/product_varients/:id",

  SERVICES: "/masters/services",
  SERVICECREATE: "/masters/services/create_service",
  SERVICE_EDIT: "/masters/services/edit_service",
  SERVICE_EDIT_ID: "/masters/services/edit_service/:id",
  VIEW_SERVICE: "/masters/services/view_service",
  VIEW_SERVICE_ID: "/masters/services/view_service/:id",

  BRANCHES: "/masters/branches",

  // TRACK_ASSIGNMENTS: "/track_assignments",
  // ASSIGN_OPPORTUNITIES: "/assign_opportunity",
  DEPARTMENTS: "/masters/departments",
  DESIGNATION: "/masters/designation",
  EMPLOYMENT_TYPE: "/masters/employment_type",
  PERMISSIONS: "/hrms/permissions",
  ROLES_SCREEN: "/hrms/roles_and_screens",
  EMPLOYEES: "/hrms/employees",
  CREATEEMPLOYEE: "/hrms/employees/createemployee",
  EDIT_EMPLOYEE: "/hrms/employees/edit_employee",
  EDIT_EMPLOYEE_ID: "/hrms/employees/edit_employee/:id",
  VIEW_EMPLOYEE: "/hrms/employees/view_employee",
  VIEW_EMPLOYEE_ID: "/hrms/employees/view_employee/:id",
  EMPLOYEEGRADE: "/masters/employeegrade",

  // { FMS }
  TRACK_ASSIGNMENTS: "/track_assignments",
  ASSIGN_OPPORTUNITIES: "/assign_opportunity",
  ASSIGN_OPPORTUNITIES_ID: "/assign_opportunity/:id",
  UPDATE_OPPORTUNITY_ASSIGNMENT: "/update_oppo_assignment",
  CREATEAGENT: "/list_agent/create_agent",
  LISTAGENT: "/list_agent",
  UPDATEAGENT: "/list_agent/update_agent",
  UPDATEAGENT_ID: "/list_agent/update_agent/:id",
  FRIGHTLIST: "/masters/fright_list",
  CARRIER: "/masters/carrier",
  MODE: "/masters/mode",
  QUATATIONS: "/crm/quotations_list",
  ADD_QUOTATION: "/crm/quotations_list/add_quotation",
  ADD_QUOTATION_ID: "/crm/quotations_list/add_quotation/:id",
  EDIT_QUOTATION: "/crm/quotations_list/edit_quotation",
  EDIT_QUOTATION_ID: "/crm/quotations_list/edit_quotation/:id",
  VIEW_QUOTATION: "/crm/quotations_list/view_quotation",
  VIEW_QUOTATION_ID: "/crm/quotations_list/view_quotation/:id",

  IMCOSTATUS: "/masters/imcostatus",
  CLEARANCEDOCUMENTS: "/masters/clearancedocuments",
  TIMESTAMPHEADS: "/masters/timestampheads",
  FINANCIALYEAR: "/masters/financialyear",

  //  purchase

  PURCHASEORDER: "/purchase/purchase_order_list/purchase_order",
  PUCHASE_ORDER_LIST: "/purchase/purchase_order_list",
  EDIT_PUCHASE_ORDER: "/purchase/purchase_order_list/edit_purchase_order",
  EDIT_PUCHASE_ORDER_ID:
    "/purchase/purchase_order_list/edit_purchase_order/:id",
  VIEW_PURCHASE_ORDER: "/purchase/purchase_order_list/view_purchase_order",
  VIEW_PURCHASE_ORDER_ID:
    "/purchase/purchase_order_list/view_purchase_order/:id",

  PURCHASEBILL: "/purchase/purchase_bill",
  ADD_PURCHASEBILL: "/purchase/purchase_bill/add_purchase_bill",
  EDIT_PURCHASEBILL: "/purchase/purchase_bill/edit_purchase_bill",
  EDIT_PURCHASEBILL_ID: "/purchase/purchase_bill/edit_purchase_bill/:id",
  VIEW_PURCHASEBILL: "/purchase/purchase_bill/view_purchase_bill",
  VIEW_PURCHASEBILL_ID: "/purchase/purchase_bill/view_purchase_bill/:id",

  PRINT_PURCHASE_BILL: "/purchase/purchase_bill/print_purchase_bill",
  PRINT_PURCHASE_BILL_ID: "/purchase/purchase_bill/print_purchase_bill/:id",

  ENQUIRIES: "/crm/opportunity_",
  AGENT_RESPONSE: "/opportunity_/agent_response",
  AGENT_RESPONSE_ID: "/opportunity_/agent_response/:id",
  EDIT_ASSIGN_OPPORTUNITY: "/opportunity_/edit_assign_opportunity",
  EDIT_ASSIGN_OPPORTUNITY_ID: "/opportunity_/edit_assign_opportunity/:id",
  CONSIGNEE: "/consignee",
  TERMS_OF_PAYMENT: "/masters/terms_of_payment",
  TAXTYPE: "/masters/tax_type",
  JOBTASKS: "/masters/job_tasks",
  AIRPORT: "/masters/airport",
  SEAPORT: "/masters/seaport",
  LOCATIONS: "/masters/locations",
  INCOTERM: "/masters/incoterm",
  ASSIGN_QUOTATION: "/assign_quotation",
  ASSIGN_QUOTATION_ID: "/assign_quotation/:id",
  LIST_JOB: "/operations/joblist",

  // ########   oldjob     #####

  // CREATEJOB: "/joblist/createjob",
  // CREATEJOB_ID: "/joblist/createjob/:id",
  // UPDATEJOB: "/joblist/updatejob",
  // UPDATEJOB_ID: "/joblist/updatejob/:id",

  VIEW_JOB: "/operations/joblist/view_job",
  VIEW_JOB_ID: "/operations/joblist/view_job/:id",

  JOB_CLOSING: "/operations/job_closing",

  CONSOL_JOB_INVOICE_PREVIEW:
    "/operations/consoljoblist/invoice_preview/generate",
  CONSOL_JOB_INVOICE_PREVIEW_ID:
    "/operations/consoljoblist/invoice_preview/generate/:id",
  CONSOL_JOB_INVOICE_PREVIEW_INV:
    "/operations/invoice_list/invoice_preview/regenerate",
  CONSOL_JOB_INVOICE_PREVIEW_INV_ID:
    "/operations/invoice_list/invoice_preview/regenerate/:id",

  INVOICE_LIST: "/operations/invoice_list",
  INVOICE_PREVIEW: "/operations/joblist/invoice_preview/generate",
  INVOICE_PREVIEW_ID: "/operations/joblist/invoice_preview/generate/:id",
  INVOICE_PREVIEW_JOB: "/operations/invoice_list/invoice_preview/regenerate",
  INVOICE_PREVIEW_JOB_ID:
    "/operations/invoice_list/invoice_preview/regenerate/:id",

  EDIT_INVOICE_PREVIEW:
    "/operations/invoice_list/edit_invoice_preview/generate",
  EDIT_INVOICE_PREVIEW_ID:
    "/operations/invoice_list/edit_invoice_preview/generate/:id",

  INVOICE_VIEW: "/operations/invoice_list/invoice_view",
  INVOICE_VIEW_ID: "/operations/invoice_list/invoice_view/:id",
  INVOICE_PRINT: "/operations/invoice_list/invoice_print",
  PRINT_INVOICE: "/operations/invoice_list/print_invoice",
  PRINT_INVOICE_ID: "/operations/invoice_list/print_invoice/:id",
  AGENT_REPORT: "/reports/agentReport",
  COST_AND_EXPENSE_REPORT: "/reports/cost_and_expense_report",
  MONTHLY_REPORT: "/reports/monthly_report",
  INVOICE_REPORT: "/reports/invoice_report",
  ENQUIRY_REPORT: "/reports/enquiry_report",
  // masters fms
  CONTAINER_TYPES: "/masters/container_type",
  CARGO_TYPE: "/masters/cargo_type",
  PENDING_ENQ: "/pending_enq",
  PENDING_ENQ_ID: "/pending_enq/:id",

  PENDING_OPPOR: "/pending_opportunity",
  PENDING_OPPOR_ID: "/pending_opportunity/:id",

  PENDING_QTN: "/pending_quotation",
  PENDING_QTN_ID: "/pending_quotation/:id",

  PENDING_RFQ: "/pending_rfq",
  PENDING_RFQ_ID: "/pending_rfq/:id",

  PENDING_ORDERS: "/pending_orders",
  PENDING_ORDERS_ID: "/pending_orders/:id",

  PENDING_PURCHASE_BILL: "/pending_purchase_bill",

  PENDING_PURCHASE_BILL_ID: "/pending_purchase_bill/:id",
  PENDING_JOB_BOOKING: "/pending_job_booking",
  PENDING_JOB_BOOKING_ID: "/pending_job_booking/:id",
  PENDING_JOB: "/pending_jobs",
  PENDING_JOB_ID: "/pending_jobs/:id",
  PENDING_INV: "/pending_invoice",
  PENDING_INV_ID: "/pending_invoice/:id",

  COMPLETED_JOB: "/completed_job",
  COMPLETED_JOB_ID: "/completed_job/:id",
  QUOTATION_TERMS: "/settings/quotation_terms",
  QUOTATION_TERMS_ID: "/settings/quotation_terms/:id",
  QUOTATION_TERMS_LIST: "/settings/quotation_term_list",
  VIEW_QTN_TERM: "/settings/view_quotation_term",
  VIEW_QTN_TERM_ID: "/settings/view_quotation_term/:id",

  // #### jobphase2

  // CREATENEWJOB:"/joblist/addjob",

  // CONSOLJOB: "/consoljob",

  CREATENEWJOB: "/operations/joblist/addjob",

  CREATEJOB_ID: "/operations/joblist/addjob/:id",

  UPDATE_JOB: "/operations/joblist/update_job",
  UPDATE_JOB_ID: "/operations/joblist/update_job/:id",

  //  ######   console job
  CONSOLJOB: "/operations/listconsol_job/addconsoljob",
  LIST_CONSOLJOB: "/operations/listconsol_job",
  UPDATE_CONSOLJOB: "/operations/listconsol_job/update_consoljob",
  UPDATE_CONSOLJOB_ID: "/operations/listconsol_job/update_consoljob/:id",

  VIEW_CONSOLJOB: "/operations/listconsol_job/view_consoljob",
  VIEW_CONSOLJOB_ID: "/operations/listconsol_job/view_consoljob/:id",
  CONSOL_JOB_TASK_AND_EXPENSE: "/operations/listconsol_job/task_and_expense",
  CONSOL_JOB_TASK_AND_EXPENSE_ID:
    "/operations/listconsol_job/task_and_expense/:id",

  // Accounts
  PAYMEMENT_MODE: "/masters/payment_mode",
  ADD_PURCHASE: "/masters/add_purchase",
  PURCHASE: "/purchase/purchase",
  CREATE_PURCHASE: "/purchase/purchaseAdd",

  //  Accounts
  DAILY_EXPENSE: "/accounts/daily_expense",
  CREATE_EXPENSE: "/accounts/daily_expense/create_expense",
  EXPENSE_CATEGORY: "/masters/expense_category",
  EDIT_EXPENSE: "/accounts/daily_expense/edit_expense",
  EDIT_EXPENSE_ID: "/accounts/daily_expense/edit_expense/:id",
  VIEW_EXPENSE: "/accounts/daily_expense/view_expense",
  VIEW_EXPENSE_ID: "/accounts/daily_expense/view_expense/:id",

  CREDIT_NOTES: "/accounts/credit_notes",
  ADD_CREDIT_NOTES: "/accounts/credit_notes/add_credit_notes",
  EDIT_CREDIT_NOTES: "/accounts/credit_notes/edit_credit_notes",
  EDIT_CREDIT_NOTES_ID: "/accounts/credit_notes/edit_credit_notes/:id",
  VIEW_CREDIT_NOTES: "/accounts/credit_notes/view_credit_notes",
  VIEW_CREDIT_NOTES_ID: "/accounts/credit_notes/view_credit_notes/:id",

  PRINT_CREDIT_NOTES: "/accounts/credit_notes/view_credit_notes/print",
  PRINT_CREDIT_NOTES_ID: "/accounts/credit_notes/view_credit_notes/print/:id",

  DEBIT_NOTES: "/accounts/debit_notes",
  ADD_DEBIT_NOTES: "/accounts/debit_notes/add_debit_notes",
  EDIT_DEBIT_NOTES: "/accounts/debit_notes/edit_debit_notes",
  EDIT_DEBIT_NOTES_ID: "/accounts/debit_notes/edit_debit_notes/:id",
  VIEW_DEBIT_NOTES: "/accounts/debit_notes/view_debit_notes",
  VIEW_DEBIT_NOTES_ID: "/accounts/debit_notes/view_debit_notes/:id",

  PRINT_DEBIT_NOTES: "/accounts/debit_notes/view_debit_notes/print",
  PRINT_DEBIT_NOTES_ID: "/accounts/debit_notes/view_debit_notes/print/:id",

  LIST_SUPPLIER_CREDITNOTES: "/accounts/list_supplier_creditnotes",
  ADD_SUPPLIER_CREDITNOTES:
    "/accounts/list_supplier_creditnotes/add_suppliercreditnotes",
  EDIT_SUPPLIER_CREDITNOTES:
    "/accounts/list_supplier_creditnotes/edit_suppliercreditnotes",
  EDIT_SUPPLIER_CREDITNOTES_ID:
    "/accounts/list_supplier_creditnotes/edit_suppliercreditnotes/:id",
  VIEW_SUPPLIER_CREDITNOTES:
    "/accounts/list_supplier_creditnotes/view_suppliercreditnotes",
  VIEW_SUPPLIER_CREDITNOTES_ID:
    "/accounts/list_supplier_creditnotes/view_suppliercreditnotes/:id",

  PRINT_SUPPLIERCREDITNOTE:
    "/accounts/list_supplier_creditnotes/view_suppliercreditnotes/print",
  PRINT_SUPPLIERCREDITNOTE_ID:
    "/accounts/list_supplier_creditnotes/view_suppliercreditnotes/print/:id",

  // PRINT_SUPPLIERCREDITNOTE:"/accounts/list_supplier_creditnotes/view_suppliercreditnotes/print/",

  /*payments routes */
  PAYMENTS: "/accounts/payments",
  ADD_PAYMENTS: "/accounts/payments/add_payments",
  VIEW_PURCHASE: "/purchase/view_purchase",
  VIEW_PURCHASE_ID: "/purchase/view_purchase/:id",
  EDIT_PURCHASE: "/purchase/edit_purchase",
  EDIT_PURCHASE_ID: "/purchase/accounts/edit_purchase/:id",
  PRINT_PURCHASE: "/purcahse/print_purchase",

  VIEW_PAYMENTS_INDEX: "/accounts/payments/view_payments",
  VIEW_PAYMENT: "/accounts/payments/view_payments/:payment_id",

  // edit

  // EDIT_PAYMENTS_INDEX: "/payments/edit_payments",
  // EDIT_PAYMENT: "/payments/edit_payments/:payment_id",

  EDIT_PAYMENT: "/accounts/payments/edit_payments",
  EDIT_PAYMENT_ID: "/accounts/payments/edit_payments/:id",
  /*payments routes end*/

  // job payment
  JOB_PAYMENTS: "/job_payments",
  ADD_JOBPAYMENT: "/job_payments/add_job_payment",
  EDIT_JOBPAYMENT: "/job_payments/edit_job_payment",
  EDIT_JOBPAYMENT_ID: "/job_payments/edit_job_payment/:id",
  VIEW_JOBPAYMENT: "/job_payments/view_job_payment",
  VIEW_JOBPAYMENT_ID: "/job_payments/view_job_payment/:id",

  //Job Booking
  ADD_JOB_BOOKING: "/operations/job_booking/add_job_booking",
  ADD_JOB_BOOKING_ID: "/operations/job_booking/add_job_booking/:id",
  LIST_JOB_BOOKING: "/operations/job_booking",
  VIEW_JOB_BOOKING: "/operations/job_booking/view_job_booking",
  VIEW_JOB_BOOKING_ID: "/operations/job_booking/view_job_booking/:id",
  EDIT_JOB_BOOKING: "/operations/job_booking/edit_job_booking",
  EDIT_JOB_BOOKING_ID: "/operations/job_booking/edit_job_booking/:id",

  //  Accounts -> Settings
  CREDIT_NOTE_TYPE: "/masters/credit_note_type",
  BANK_DETAILS: "/masters/list_bankdetails",

  // Accounts -> Reports
  DAILY_EXPENSE_REPORT: "/reports/daily_expense_report",

  // general settings
  CURRENCY: "/settings/currency",
  COMPANYINFO: "/settings/company_info",
  TASKANDEXPENSES: "/operations/joblist/task_expenses",
  TASKANDEXPENSES_ID: "/operations/joblist/task_expenses/:id",
  QUATATION_INVOICE: "/crm/quotation_invoice",
  PRINT_TASKEXPENCE: "/operations/joblist/task_expenses/print_taskepence",
  PRINT_TASKEXPENCE_ID:
    "/operations/joblist/task_expenses/print_taskepence/:id",

  EMAIL_SCHEDULES: "/settings/email_schedule",
  // // Quotation print
  // QUATATION_INVOICE_PRINT: "/quotationPrint",

  QUATATION_INVOICE_ID: "/crm/quotation_invoice/:id",
  PURCHASEORDER_INVOICE: "/purchase/purchase_order_invoice",
  PURCHASEORDER_INVOICE_ID: "/purchase/purchase_order_invoice/:id",
  JOB_INVOICE: "/operations/joblist/job_invoice",
  JOB_INVOICE_ID: "/operations/joblist/job_invoice/:id",
  VENDOR_TYPE: "/masters/vendor_type",
  VENDOR: "/crm/vendor",
  ADDVENDOR: "/crm/vendor/add_vendor",
  UPDATE_VENDOR: "/crm/vendor/update_vendor",
  UPDATE_VENDOR_ID: "/crm/vendor/update_vendor/:id",
  VIEW_VENDOR: "/crm/vendor/view_vendor",
  VIEW_VENDOR_ID: "/crm/vendor/view_vendor/:id",
  FMSSETTINGS: "/settings/fmssettings",
  SELLINGPRICE: "/settings/Selling_price",
  DIAMENTIONCALCULATION: "/settings/DiamentionCalculation",

  // CRM -> sales => Enquiry
  CREATE_ENQUIRY: "/crm/enquiry_list/create_enquiry",
  ENQUIRY_LIST: "/crm/enquiry_list",
  EDIT_ENQUIRY: "/crm/enquiry_list/edit_enquiry",
  EDIT_ENQUIRY_ID: "/crm/enquiry_list/edit_enquiry/:id",
  VIEW_ENQUIRY: "/crm/enquiry_list/view_enquiry",
  VIEW_ENQUIRY_ID: "/crm/enquiry_list/view_enquiry/:id",
  ENQUIRY_SOURCE: "/masters/enquiry_source",

  // Accounts => Bill Payments
  CREATE_BILL_PAYMENT: "/purchase/bill_payment_list/create_bill_payment",
  EDIT_BILL_PAYMENT: "/purchase/bill_payment_list/edit_bill_payment",
  EDIT_BILL_PAYMENT_ID: "/purchase/bill_payment_list/edit_bill_payment/:id",
  BILL_PAYMENT_LIST: "/purchase/bill_payment_list",
  VIEW_BILL_PAYMENT: "/purchase/bill_payment_list/bill_payment_view",
  VIEW_BILL_PAYMENT_ID: "/purchase/bill_payment_list/bill_payment_view/:id",

  //FMS => masters => Tax Group
  TAX_GROUP: "/masters/tax_groups",
  AWBBL_REPORT: "/reports/awb_bl_report",

  //purchase bill Report
  PURCHASE_BILL_REPORT: "/reports/purchase_bill_report",

  // ACCOUNTS => masters => Legder
  LEDGER: "/masters/ledger",
  GL_TYPE: "/masters/gl_type",
  ACCOUNTS_SETTINGS: "/settings/accounts_settings",
  OPENING_BALANCE: "/accounts/opening_balance",
  CHART_OF_ACCOUNT: "/reports/chart_of_account",

  // ACCOUNTS => Master => Account Group
  ACC_GROUP: "/masters/account_groups",

  // testingg invoice

  INVOICETEMPLATE: "/invoicetemplate1",
  INVOICE_NEWTEMP: "/invoicetemplate2",
  INVOICE_TEMPLATE3: "/invoicetemplate3",

  PRINTPAGE: "/print",
  // selectinginvoicetemplates

  SELECT_INVOICETEMPLATE: "/settings/selectinvoicetemplate",

  // View Invoices From job
  VIEW_INVOICES: "/operations/view_invoices",
  VIEW_INVOICES_ID: "/operations/view_invoices/:id",

  // Denied Permission
  DENIED_PERMISSION: "/403_forbidden",
  PAGE_NOT_FOUND: "/pageNotFound",

  //Add RFQ
  ADD_RFQ: "/purchase/list_rfq/add_rfq",
  ADD_RFQ_ID: "/purchase/list_rfq/add_rfq/:id",
  LIST_RFQ: "/purchase/list_rfq",
  VIEW_RFQ: "/purchase/list_rfq/view_rfq",
  VIEW_RFQ_ID: "/purchase/list_rfq/view_rfq/:id",
  UPDATE_RFQ: "/purchase/list_rfq/update_rfq",
  UPDATE_RFQ_ID: "/purchase/list_rfq/update_rfq/:id",
  CLONE_RFQ: "/purchase/list_rfq/clone_rfq",
  CLONE_RFQ_ID: "/purchase/list_rfq/clone_rfq/:id",

  // --------accounts => Receipts----------------
  VIEW_RECIPTS: "/accounts/list_receipts/view_receipts",
  VIEW_RECIPTS_ID: "/accounts/list_receipts/view_receipts/:id",
  ADD_RECEIPT: "/accounts/list_receipts/add_receipts",
  ADD_RECEIPT_ID: "/accounts/list_receipts/add_receipts/:id",
  EDIT_RECEIPT: "/accounts/list_receipts/edit_receipts",
  EDIT_RECEIPT_ID: "/accounts/list_receipts/edit_receipts/:id",
  //Account Receipts
  LIST_Receipts: "/accounts/list_receipts",

  RECIEPT_DETAILMODAL: "/accounts/listreceipt_modal",
  // CLONE_RFQ_ID: "list_rfq/clone_rfq/:id",

  // ----------bann reconciliation-------
  BANK_RECONCILIATION: "/accounts/bank_reconciliation",

  // ---------Journal entry---------//\
  ADD_JOURNAL_ENTRY: "/accounts/journal_entry/add_journal_entry",
  LIST_JOURNAL_ENTRY: "/accounts/journal_entry",
  EDIT_JOURNAL_ENTRY: "/accounts/journal_entry/edit_journal_entry",
  EDIT_JOURNAL_ENTRY_ID: "/accounts/journal_entry/edit_journal_entry/:id",
  VIEW_JOURNAL_ENTRY: "/accounts/journal_entry/view_journal_entry",
  VIEW_JOURNAL_ENTRY_ID: "/accounts/journal_entry/view_journal_entry/:id",
  ///..............Reports Starts..................///
  STATEMENT_OF_ACCOUNTS: "/reports/statement_of_accounts",
  // profit and loss
  PROFIT_AND_LOSS: "/reports/profit_and_loss",
  // aging report
  AGEING: "/reports/ageing",
  TRIAL_BALANCE: "/reports/tiral_balance",
  BALANCE_SHEET: "/reports/balance_sheet",
  SALES_PERSON_REVENUE_REPORT: "/reports/sales_person_revenue_report",
  REFUND_HISTORY: "/reports/refund_history",
  DAILY_SALES_REPORT: "/reports/daily_salesreport",
  PENDING_INVOICES: "/reports/pending_invoices",
  CUSTOMERWISE_REPORT: "/reports/customerwise_report",

  PRINT_CUSTOMERWISE_REPORT:
    "/reports/customerwise_report/customerwisereport_print",

  // -----dashboard---------
  CRM_TAB: "/dashboard/crm_tab_dashboard",
  PERMISSIONDEN: "/permission_denined",
  SHIPMENT_STATUS_REPORT: "/reports/shipment_status_report",

  // arrivalnoticeprint

  PRINT_ARRIVALNOTICE: "/operations/joblist/arrivalnotice_print",
  PRINT_ARRIVALNOTICE_ID: "/operations/joblist/arrivalnotice_print/:id",

  // Crm New Lead Pages
  CRM_LEADLIST: "/crm/leadList",
  CRM_ADDLEAD: "/crm/leadList/create_lead",
  CRM_ADDLEAD_ID: "/crm/leadList/create_lead/:id",
  CRM_EDITLEAD: "/crm/leadList/edit_lead",
  CRM_EDITLEAD_ID: "/crm/leadList/edit_lead/:id",
  CRM_VIEWLEAD: "/crm/leadList/view_lead",
  CRM_VIEWLEAD_ID: "/crm/leadList/view_lead/:id",

  // print Receipts
  PRINT_RECEIPT: "/accounts/list_receipts/view_receipts/print",
  PRINT_RECEIPT_ID: "/accounts/list_receipts/view_receipts/print/:id",

  // print shipmentstatusreport

  PRINT_SHIPMENTSTATUS_REPORT:
    "/reports/shipment_status_report/print_shipmentstatus_report",
  PRINT_SHIPMENT_PENDING_BOOKINGS:
    "/reports/shipment_pending_bookings/print_shipment_pending_bookings",

  SHIPMENT_PENDING_BOOKINGS: "/reports/shipment_pending_bookings",

  SHIPMENT_PENDING_JOBS: "/reports/shipment_pending_jobs",

  PRINT_SHIPMENT_PENDING_JOBS:
    "/reports/shipment_pending_jobs/print_shipment_pending_jobs",

  PRINT_COSTANDEXPENSE_REPORT:
    "/reports/cost_and_expense_report/print_cost_and_expense_report",

  PRINT_MONTHLY_REPORT: "/reports/print_monthly_report",
  PRINT_INVOICE_REPORT: "/reports/print_invoice_report",
  PRINT_DAILYSALES_REPORT: "/reports/print_dailysales_report",
  PRINT_AGENT_REPORT: "/reports/print_agent_report",
  PRINT_PURCHASE_BILLREPORT: "/reports/print_purchase_billreport",
  PRINT_STATEMENT_OF_ACCOUNTS: "/reports/print_statement_ofaccounts",
  PRINT_PENDING_INVOICES: "/reports/print_pending_invoices",
  PRINT_AWBBL_REPORT: "/reports/print_awbbl_report",

  PRINT_PROFIT_AND_LOSS: "/reports/print_profit_and_loss",
  PRINT_TRIAL_BALANCE: "/reports/print_trial_balance",
  PRINT_AGEING_REPORT: "/reports/print_ageing_report",
  PRINT_BALANCE_SHEET: "/reports/print_balance_sheet_report",

  // ******** Account Advance Payment ************//
  ADVANCE_PAYMENT_LIST: "/accounts/advance_payment",
  ADD_ADVANCE_PAYMENT: "/accounts/advance_payment/add_advance_payment",
  ADD_ADVANCE_PAYMENT_ID: "/accounts/advance_payment/add_advance_payment/:id",
  EDIT_ADVANCE_PAYMENT: "/accounts/advance_payment/edit_advance_payment",
  EDIT_ADVANCE_PAYMENT_ID: "/accounts/advance_payment/edit_advance_payment/:id",
  VIEW_ADVANCE_PAYMENT: "/accounts/advance_payment/view_advance_payment",
  VIEW_ADVANCE_PAYMENT_ID: "/accounts/advance_payment/view_advance_payment/:id",

  // component invoiceprinttemp4
  PRINT_INVOICE_TEMP4: "/operations/invoice_list/invoicetemp4",
  PRINT_INVOICE_TEMP5: "/operations/invoice_list/invoicetemp5",

  //
  PRINT_PAYMENT: "/accounts/payments/print",
  PRINT_PAYMENT_ID: "/accounts/payments/print/:id",

  //
  PRINT_DELIVERYNOTE: "/operations/joblist/view_job/deliverynote",
  PRINT_DELIVERYNOTE_ID: "/operations/joblist/view_job/deliverynote/:id",
};

export default ROUTES;
