import React, { useEffect, useState } from "react";
import PublicFetch from "../../utils/PublicFetch";
import ROUTES from "../../routes/index";
import { useLocation, useNavigate } from "react-router-dom";
import Avatar from "../../components/img/img_avatar.png";
import HeaderIcon from "../../components/img/HeaderIcon.png";
import { IoMdNotifications } from "react-icons/io";
import { TfiKey } from "react-icons/tfi";
import { HiLogout } from "react-icons/hi";
import { Dropdown, Form, Input, Space, message } from "antd";
import styles from "./header.module.scss";
import CustomModel from "../custom_modal/custom_model";
import Button from "../button/button";
import "./headercss.scss";
import { AiOutlineMenu } from "react-icons/ai";
import {
  GENERAL_SETTING_BASE_URL,
  CRM_BASE_URL_HRMS,
  CRM_BASE_URL,
} from "../../api/bootapi";
import TableData from "../table/table_data";
import moment from "moment";
import CustomerFetch from "../../utils/CustomerFetch";

const Header = ({ showSidebar, setShowMenu, nameToPass }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const [notiModal, setNotiModal] = useState(false);

  const [notiClicked, setNotiClicked] = useState();

  const [changeModal, setChangeModal] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [oldpassword, setoldpassword] = useState();
  const [newPassword, setnewPassword] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const [User, setUser] = useState();
  const [notificationCount, setNotificationCount] = useState();
  const [expDocs, setExpDocs] = useState([]);
  const [messages, setMessages] = useState([]);
  const [dailySalesCount, setDailySalesCount] = useState(0);
  const [dailySalesData, setDailySalesData] = useState();
  const [DailySalesModal, setDailySalesModal] = useState();
  const [addForm] = Form.useForm();

  const data = [
    {
      id: 1,
      name: "Quotation Created SuccessFully",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 2,
      name: "Job Created SuccessFully",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 3,
      name: "Invoice Created SuccessFully",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
    {
      id: 4,
      name: "Opportunity Created SuccessFully",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ];
  const [allNotifications, setAllNotifications] = useState(data);
  const [companyInfodata, setCompanyInfodata] = useState();

  const [expDocModal, setExpDocModal] = useState(false);

  const Exp_Docs = [
    {
      title: "Slno",
      dataIndex: "slno",
      key: "slno",
      render: (data, index, slno) => {
        return <>{slno + 1}</>;
      },
    },
    {
      title: "CUSTOMER",
      dataIndex: "customer",
      key: "customer",
      render: (data, index) => {
        console.log("nfgmdn", index);
        return (
          <>
            {
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(
                    `${ROUTES.CUSTOMER_EDIT}/${index?.customer_docs_customer_id}?tabIndex=6`
                  );
                  setExpDocModal(false);
                  setNotiModal(false);
                }}
              >
                {index?.crm_v1_customer?.customer_name}
              </div>
            }
          </>
        );
      },
    },
    {
      title: "DOCUMENT",
      dataIndex: "ducument_name",
      key: "ducument_name",
      render: (data, index) => {
        return (
          <>{index?.fms_v1_clearance_documents?.clearance_documents_name}</>
        );
      },
    },
    // {
    //   title: "DOCUMENT",
    //   dataIndex: "ducument_name",
    //   key: "ducument_name",
    // },
  ];

  //Handle dropdown options click event
  const handleDropdownClick = (event) => {
    console.log(event, "Events on Click");
    if (event.key === "1") {
      console.log("Clicked Super Admin");
      return false;
    } else if (event.key === "3") {
      LogoutUser();
      console.log("Clicked on Logout");
    }
  };

  // ------------find the users name

  const userid = localStorage.getItem("UserID");

  useEffect(() => {
    getUser(userid);
  }, []);
  const getUser = (uid) => {
    if (pathname[1] !== "customer") {
      PublicFetch(`${CRM_BASE_URL_HRMS}/employees/${uid}`)
        .then((res) => {
          console.log("userdataaa", res, uid);
          setUser(res?.data?.data?.employee_name);
          let a = {
            userName: res?.data?.data?.employee_name,
            userMail: res?.data?.data?.users[0]?.user_email,
          };
          let data = JSON.stringify(a);
          localStorage.setItem("userData", data);
        })
        .catch((err) => {
          console.log("error from the header", err);
        });
    }
  };

  // ----------end-------------

  //Api call for when logout clicked
  const LogoutUser = async () => {
    if (pathname[1] === "customer") {
      setTimeout(() => {
        navigate(ROUTES.CUSTOMER_LOGIN);
      }, 1000);
      localStorage.removeItem("CustomerToken");
      // localStorage.clear("CustomerToken");
    } else {
      try {
        const logoutUser = await PublicFetch.get(
          `${process.env.REACT_APP_BASE_URL}/auth/logout`
        );

        if (logoutUser?.status === 200) {
          localStorage.clear("UserToken");
          localStorage.removeItem("UserToken");
          navigate(ROUTES.LOGIN);
        }
      } catch (err) {
        // let errorMessage = parseAxiosError(err);
        // message.error(errorMessage);
        console.log("Error while Logout");
      }
    }
  };

  const handleChangePassword = () => {
    setChangeModal(true);
  };

  const close_modal = (mShow, time) => {
    if (!mShow) {
      setTimeout(() => {
        setModalShow(false);
        // navigate(ROUTES.LOGIN)
      }, time);
    }
  };

  const changepassword = async (data) => {
    let a = "";
    console.log("user issss", data);
    if (data.new_password !== data.confirm_password) {
      message.error("Passwords do not match");
    } else {
      try {
        if (pathname[1] === "customer") {
          const changepswrd = await CustomerFetch.post(
            `${process.env.REACT_APP_BASE_URL}/auth/customer/changepassword`,
            {
              currentPassword: data.old_password,
              newPassword: data.new_password,
            }
          );

          console.log("chnge password iss", changepswrd);
          if (changepswrd.data.success) {
            setModalShow(true);
            LogoutUser();

            addForm.resetFields();
            setChangeModal(false);
            close_modal(modalShow, 1000);
          }
        } else {
          const changepswrd = await PublicFetch.post(
            `${process.env.REACT_APP_BASE_URL}/auth/changepassword`,
            {
              currentPassword: data.old_password,
              newPassword: data.new_password,
            }
          );

          console.log("chnge password iss", changepswrd);
          if (changepswrd.data.success) {
            setModalShow(true);
            LogoutUser();

            addForm.resetFields();
            setChangeModal(false);
            close_modal(modalShow, 1000);
          }
        }
      } catch (err) {
        // let errorMessage = parseAxiosError(err);
        // message.error(errorMessage);
        console.log("Error while change password");
      }
    }
  };
  const handleonchange = (e) => {
    if (newPassword !== confirmpassword) {
      message.error("password is incorrect");
    }
  };

  //Array containing dropdown options
  const items = [
    {
      key: "1",
      label: (
        <>
          <div>
            {pathname[1] !== "customer" ? (
              <>
                <p className={`${styles.Welcome} mt-2`}>{User}</p>
              </>
            ) : (
              ""
            )}

            {/* <p className={`${styles.DrpdwnUser}`}>Super Admin</p>
            <hr className={`${styles.HorizontalRule}`}></hr> */}
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div
            onClick={() => {
              handleChangePassword();
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <p className={`${styles.HeaderOptions}`}>
              Change Password
              <span className="ps-2">
                <TfiKey size={15} />
              </span>
            </p>
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <div>
            <p className={`${styles.HeaderOptions}`}>
              Logout
              <span className="ps-2">
                <HiLogout />
              </span>
            </p>
          </div>
        </>
      ),
    },
  ];

  const dailySalesHead = [
    {
      title: "Slno",
      dataIndex: "slno",
      key: "slno",
      render: (data, index, slno) => {
        return <>{slno + 1}</>;
      },
    },
    {
      title: "Name",
      dataIndex: "slno",
      key: "slno",
      render: (data, index, slno) => {
        return <>{index?.crm_v1_leads?.lead_company_name}</>;
      },
    },
    {
      title: "Contact Person",
      dataIndex: "slno",
      key: "slno",
      render: (data, index, slno) => {
        let a = index?.crm_v1_leads?.crm_v1_lead_contacts[0]?.lead_contact_name;
        return <>{a}</>;
      },
    },
    {
      title: "Phone",
      dataIndex: "slno",
      key: "slno",
      render: (data, index, slno) => {
        let a =
          index?.crm_v1_leads?.crm_v1_lead_contacts[0]?.lead_contact_phone;
        return <>{a}</>;
      },
    },
    {
      title: "FollowUp Date",
      dataIndex: "slno",
      key: "slno",
      render: (data, index, slno) => {
        return (
          <>
            {moment(index?.lead_daily_sale_next_followup_date).format(
              "DD-MM-YYYY"
            )}
          </>
        );
      },
    },
  ];

  const menuProps = {
    items,
    onClick: handleDropdownClick,
  };

  const handlenotification = () => {
    setNotiModal(true);
  };
  const handleShowContent = (data) => {
    setNotiClicked(data);
  };

  const companyinfo = () => {
    PublicFetch.get(`${GENERAL_SETTING_BASE_URL}/company`)
      .then((res) => {
        console.log("Response of company info", res);
        if (res.data.success) {
          console.log("SuccessFull of company info", res.data.data);
          setCompanyInfodata(res.data.data);
          localStorage.setItem("taxable", res.data.data[0]?.taxable);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const [ClickedMenu, setClickedMenu] = useState();
  const [clickedItem, setClickedItem] = useState();

  // expired documents

  const getexpiredDoc = async () => {
    try {
      if (pathname[1] === "customer") {
        const expdocuments = await CustomerFetch.get(
          `${CRM_BASE_URL}/customer_documents/expired-documents`
        );
        console.log("getting expdocs count", expdocuments?.data?.data?.length);
        setExpDocs(expdocuments?.data?.data);
        setNotificationCount(expdocuments?.data?.data?.length);
      } else {
        const expdocuments = await PublicFetch.get(
          `${CRM_BASE_URL}/customer_documents/expired-documents`
        );
        console.log("getting expdocs count", expdocuments?.data?.data?.length);
        setExpDocs(expdocuments?.data?.data);
        setNotificationCount(expdocuments?.data?.data?.length);
      }
    } catch (err) {
      console.log("error to fetching exp docs", err);
    }
  };

  // Daily Sales
  const DailySales = () => {
    let a = 0;
    if (pathname[1] !== "customer") {
      PublicFetch.get(
        `${CRM_BASE_URL}/lead/dailysale/follow-up-count?noOfDays=2`
      )
        .then((res) => {
          if (res?.data?.success) {
            setDailySalesCount(res?.data?.data?.followUpCount);
            a = res?.data?.data?.followUpCount;
            if (res?.data?.data?.followUpCount > 0) {
              PublicFetch.get(
                `${CRM_BASE_URL}/lead/dailysale/follow-up-data?noOfDays=2`
              )
                .then((res) => {
                  if (res?.data?.success) {
                    console.log("Daily Sales notifications", res?.data?.data);
                    setDailySalesData(res?.data?.data);
                  }
                })
                .catch((err) => {
                  console.log("Error", err);
                });
            }
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  const checkUserIsUpdatedPassword = async () => {
    try {
      if (pathname[1] === "customer") {
        const res = await CustomerFetch.get(
          `${process.env.REACT_APP_BASE_URL}/auth/check-user/updated-passward`
        );

        if (res?.data?.success === true) {
          if (res?.data?.data?.checked === true) {
            setChangeModal(true);
            message.warning("Please update the password");
          }
        }
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    const eventSource = new EventSource(
      `${process.env.REACT_APP_BASE_URL}/notification/count`
    );

    eventSource.onmessage = (event) => {
      // console.log("Responsfrom event source", JSON.parse(event?.data));
      let a = JSON.parse(event?.data);
      setMessages(a?.count);
    };

    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    DailySales();
    companyinfo();
    getexpiredDoc();
  }, [notiModal]);

  useEffect(() => {
    checkUserIsUpdatedPassword();
  }, []);

  // logs
  console.log("Response messages for header", messages);

  return (
    <>
      <div className="">
        <div style={{ backgroundColor: "whitesmoke" }} className="py-2">
          <div className="row  ">
            <div className="col-6 pt-2 ps-1 pe-0 me-0 ">
              {/* ${clickedItem == "dashboard" ? "d-flex" : "d-none"} */}
              <div
                className={`
               
                  `}
              >
                {companyInfodata &&
                  companyInfodata.length > 0 &&
                  companyInfodata?.map((item, indx) => {
                    localStorage.setItem(
                      "NumberOfDecimals",
                      item.number_of_decimal
                    );
                    return (
                      <div className="logo_img">
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/${item?.company_logo}`}
                          alt=""
                          className="image_log_style"
                          // height={60}
                          // width={220}
                        />
                      </div>
                    );
                  })}

                <div
                  to=""
                  // className=""
                  className="nav-link navbar-toggle nav-close d-none"
                  onClick={showSidebar}
                >
                  {" "}
                  <AiOutlineMenu
                    style={{ fontSize: "25px", color: "#16528d" }}
                  />
                  {/* <i
                    className=""
                    style={{ fontSize: "25px", color: "white" }}
                  /> */}
                </div>
              </div>
              {/* <SubMenu /> */}
            </div>
            {/* <div className="col-xl-9 col-lg-9 pt-2 d-none  ps-0 pe-0 ms-0 ps-0">
              <SubMenu />
            </div> */}
            <div
              // style={{ paddingLeft: "3%", marginLeft: "2%" }}
              className="col-6  d-flex justify-content-end "
            >
              <div
                className={`${styles.headerContainer} d-flex align-items-center justify-content-end gap-3`}
              >
                <div
                  className={`${styles.notification_icon}`}
                  onClick={() => {
                    handlenotification();
                  }}
                >
                  <span className={`${styles.icon_style}`}>
                    <lable className={`${styles.notif_count}`}>
                      {messages}
                    </lable>
                  </span>
                  <IoMdNotifications
                    size={22}
                    color="#6B728E"
                    className={`${styles.NotificationIcon}`}
                  />
                </div>
                <div className={`${styles.ImgWrapper}`}>
                  <Dropdown
                    menu={menuProps}
                    placement="bottomRight"
                    overlayClassName="dropdwnHeader"
                  >
                    {/* <Space> */}
                    <img src={HeaderIcon} alt="..." />
                    {/* </Space> */}
                  </Dropdown>
                </div>
              </div>
              <CustomModel
                show={notiModal}
                onHide={() => {
                  setNotiModal(false);
                }}
                View_list
                list_content={
                  <>
                    <div className="container-fluid">
                      <div className="row">
                        <h4 style={{ color: "#0891d1" }}>Notifications</h4>
                        <div className="col-12">
                          {/* {allNotifications &&
                            allNotifications.length > 0 &&
                            allNotifications.map((item, index) => {
                              return ( */}
                          <div className="row my-2 py-2 ">
                            <div className="col-2">
                              <div
                                style={{
                                  borderRadius: "100%",
                                  backgroundColor: "whitesmoke",
                                }}
                                className={`p-3`}
                              >
                                <IoMdNotifications
                                  size={22}
                                  color="#6B728E"
                                  className={`${styles.NotificationIcon}`}
                                />
                              </div>
                            </div>
                            <div className="col-10">
                              <div
                                className="mt-3 "
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  // handleShowContent(item.id);
                                  setExpDocModal(true);
                                }}
                              >
                                {notificationCount > 0 && (
                                  <h6 style={{ fontWeight: "600" }}>
                                    {/* {item.name} */}
                                    {notificationCount} documents are expired
                                  </h6>
                                )}
                              </div>
                              {/* {notiClicked == item.id ? ( */}
                              <>
                                <div className="">
                                  {/* <p>{item.description}</p> */}
                                  {/* <p>{notificationCount} documents are expired </p> */}
                                </div>
                              </>
                              {/* ) : (
                                      ""
                                    )} */}
                            </div>
                          </div>
                          {/* //   );
                            // })} */}
                        </div>
                        <div className="col-12">
                          {/* {allNotifications &&
                            allNotifications.length > 0 &&
                            allNotifications.map((item, index) => {
                              return ( */}
                          {dailySalesCount > 0 && (
                            <div className="row my-2 py-2 ">
                              <div className="col-2">
                                <div
                                  style={{
                                    borderRadius: "100%",
                                    backgroundColor: "whitesmoke",
                                  }}
                                  className={`p-3`}
                                >
                                  <IoMdNotifications
                                    size={22}
                                    color="#6B728E"
                                    className={`${styles.NotificationIcon}`}
                                  />
                                </div>
                              </div>
                              <div className="col-10">
                                <div
                                  className="mt-3 "
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    // handleShowContent(item.id);
                                    setDailySalesModal(true);
                                    setNotiModal(false);
                                  }}
                                >
                                  {dailySalesCount > 0 && (
                                    <h6 style={{ fontWeight: "600" }}>
                                      {/* {item.name} */}
                                      Sales Followup - {dailySalesCount}
                                    </h6>
                                  )}
                                </div>
                                {/* {notiClicked == item.id ? ( */}
                                <>
                                  <div className="">
                                    {/* <p>{item.description}</p> */}
                                    {/* <p>{notificationCount} documents are expired </p> */}
                                  </div>
                                </>
                                {/* ) : (
                                      ""
                                    )} */}
                              </div>
                            </div>
                          )}
                          {/* //   );
                            // })} */}
                        </div>
                      </div>
                    </div>
                  </>
                }
              />

              {/* {***********Daily Sales Notify Modal*********} */}
              <CustomModel
                show={DailySalesModal}
                onHide={() => {
                  setDailySalesModal(false);
                  setNotiModal(true);
                }}
                View_list
                list_content={
                  <div className="p-2">
                    <TableData columns={dailySalesHead} data={dailySalesData} />
                  </div>
                }
              />

              <CustomModel
                show={changeModal}
                onHide={() => {
                  setChangeModal(false);
                }}
                View_list
                list_content={
                  <>
                    <div className="container">
                      <Form
                        form={addForm}
                        onFinish={(data) => {
                          console.log("valuesss", data);
                          changepassword(data);
                        }}
                        onFinishFailed={(error) => {
                          console.log(error);
                        }}
                      >
                        <div className="row">
                          <h4 style={{ color: "#0891d1" }}>Change Password</h4>
                          <div className="col-12 mt-3">
                            <label>Old Password</label>
                            <Form.Item
                              name="old_password"
                              rules={[
                                {
                                  required: true,
                                  message: "Password is Required ",
                                },
                                {
                                  min: 8,
                                  message: "Required Minimum 8 Characters",
                                },
                              ]}
                            >
                              <Input.Password
                                value={oldpassword}
                                onChange={(e) => {
                                  setoldpassword(e.target.value);
                                }}
                                className={`${styles.password_style} p-2`}
                                style={{
                                  border: 0,
                                  backgroundColor: "whitesmoke",
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-12 mt-2">
                            <label>New Password</label>
                            <Form.Item
                              name="new_password"
                              rules={[
                                {
                                  required: true,
                                  message: "Password is Required ",
                                },
                                {
                                  min: 8,
                                  message: "Required Minimum 8 Characters",
                                },
                              ]}
                            >
                              <Input.Password
                                value={newPassword}
                                onChange={(e) => {
                                  setnewPassword(e.target.value);
                                }}
                                className={`${styles.password_style} p-2`}
                                style={{
                                  border: 0,
                                  backgroundColor: "whitesmoke",
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-12 mt-2">
                            <label>Confirm Password</label>
                            <Form.Item
                              name="confirm_password"
                              rules={[
                                {
                                  required: true,
                                  message: "Password is Required ",
                                },
                                {
                                  min: 8,
                                  message: "Required Minimum 8 Characters",
                                },
                              ]}
                            >
                              <Input.Password
                                value={confirmpassword}
                                onChange={(e) => {
                                  // handleonchange(e)
                                  setconfirmpassword(e.target.value);
                                }}
                                className={`${styles.password_style} p-2`}
                                style={{
                                  border: 0,
                                  backgroundColor: "whitesmoke",
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="col-12 mt-3 d-flex justify-content-center">
                            <div className="">
                              <Button type="submit" btnType="save">
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </>
                }
              />

              <CustomModel
                show={expDocModal}
                onHide={() => {
                  setExpDocModal(false);
                }}
                View_list={true}
                list_content={
                  <>
                    <div className="">
                      <TableData columns={Exp_Docs} data={expDocs} />
                    </div>
                  </>
                }
              />

              <CustomModel
                size={`sm`}
                success
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
