import { DatePicker, Form, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { ACCOUNTS } from "../../../../api/bootapi";
import Button from "../../../../components/button/button";
import LeadlistIcons from "../../../../components/lead_list_icon/lead_list_icon";
import SelectBox from "../../../../components/Select Box/SelectBox";
import TableData from "../../../../components/table/table_data";
import PublicFetch from "../../../../utils/PublicFetch";
import moment from "moment";
import Layout from "../../../../layouts/layout";

function RefundHistory() {
  const [AddForm] = Form.useForm();
  const [Ledgers, setLedgers] = useState();
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [OpenningBal, setOpenningBal] = useState(0);
  const [NoofDecimal, setNoOfDecimal] = useState(
    localStorage.getItem("NumberOfDecimals")
  );
  const [alldata, setAllData] = useState();
  const [Create, setCreate] = useState(false);
  const [Read, setRead] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [Update, setUpdate] = useState(false);
  console.log("vij",tableData);
  // Columns for Table
  const columns = [
    {
      title: "SL NO.",
      dataIndex: "slno",
      key: "slno",
      render: (data, index, slno) => {
        return <div>{slno + 1} </div>;
      },
    },
    {
      title: "REF NO",
      dataIndex: "ref_no",
      key: "ref_no",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (data, index) => {
        return <div>{index?.amount?.toFixed(NoofDecimal)}</div>;
      },
    },
    {
      title: "Credit Note Type",
      dataIndex: "acc_voucher_cr_amt_lx",
      key: "acc_voucher_cr_amt_lx",
      align: "center",
      render: (data, index) => {
        return <div>{index?.acc_voucher_cr_amt_lx?.toFixed(NoofDecimal)}</div>;
      },
    },
    {
      title: "Particulars",
      dataIndex: "particulars",
      key: "particulars",
      align: "left",
    },
  ];
  const Types = [
    {
      id: 1,
      value: "customer",
    },
    {
      id: 2,
      value: "vendor",
    },
    {
      id: 3,
      value: "both",
    },
  ];

  // Api Calls
  // Get All Ledgers Minimal
  // const GetLedgersMinimal = () => {
  //   PublicFetch.get(`${ACCOUNTS}/acc_ledger/minimal`)
  //     .then((res) => {
  //       if (res?.data?.success) {
  //         setLedgers(res?.data?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error", err);
  //     });
  // };

  const GetLedgers = (value) => {
    if (value === "customer") {
      PublicFetch.get(`${ACCOUNTS}/acc_ledger/customer`)
        .then((res) => {
          if (res?.status === 200) {
            setLedgers(res?.data?.data);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else if (value === "vendor") {
      PublicFetch.get(`${ACCOUNTS}/acc_ledger/vendor`)
        .then((res) => {
          if (res?.status === 200) {
            setLedgers(res?.data?.data);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      PublicFetch.get(`${ACCOUNTS}/acc_ledger/minimal`)
       .then((res) => {
          if (res?.status === 200) {
            setLedgers(res?.data?.data);
          }
        })
       .catch((err) => {
          console.log("Error", err);
        });
    }
  }
  // Statements of AccountsData

  const GetRefundHistory = (data) => {
    if (data) {
      let date_from = data?.rf_hs_date_from ? moment(data?.rf_hs_date_from) : null;
      let date_to = data?.rf_data_from ? moment(data?.rf_hs_date_to) : null;
      let rf_data_from = {
        type: data?.rf_hs_ledger_type,
        acc_ledger_id: data?.rf_hs_ledger,
        date_from: date_from ,
        date_to: date_to,
      }
      if( rf_data_from?.date_from === null || rf_data_from?.date_from === undefined ){
        delete rf_data_from?.date_from
      }
      if ( rf_data_from?.date_to === null || rf_data_from?.date_to === undefined){
        delete rf_data_from?.date_to
      }

      console.log("skwjfe", rf_data_from);
      const datas = rf_data_from
      PublicFetch.post(`${ACCOUNTS}/reports/refund_history`, datas)
        .then((res) => {
          if (res?.data?.success) {
            console.log("vijay",res?.data?.data);
            setAllData(res?.data?.data);
            // setOpenningBal(res?.data?.data?.openingStockAmount);
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  useEffect(() => {
    if (alldata && alldata.length > 0) {
      let temp = alldata.map((item) => ({
        ref_no: item?.acc_crn_voucher_no || item?.acc_supplier_crn_voucher_no,
        currency: item?.generalsettings_v1_currency?.currency_code || item?.generalsettings_v1_currency?.currency_code,
        amount: item?.acc_crn_total_fx,
        particulars: item?.acc_crn_particulars || item?.acc_supplier_crn_particulars,
      }));
      setTableData(temp);
      console.log("TempData:", temp);
    } else {
      setTableData([]);
    }
  }, [alldata]);

  // UseEffect for Api Calls
  useEffect(() => {
    // GetLedgersMinimal();
    GetLedgers()
  }, []);

  // useEffect(()=> {
  //   GetLedgers();
  // })
  // for Icons prints and else
  const columnsKeys = columns.map((column) => column.key);

  const [selectedColumns, setSelectedColumns] = useState(columnsKeys);
  const filteredColumns = columns.filter((column) =>
    selectedColumns.includes(column.key)
  );
  console.log("filtered columns::", filteredColumns);
  const onChange1 = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  //for Xlsx data
  const UnitHeads = [
    ["Slno", "REF NO", "Currency", "Amount", "Credit Note Type", "Particulars"],
  ];
  //for pdf download
  const data12 = tableData?.map((item, index) => [
    index + 1,
    item?.ref_no,
    item?.currency,
    item?.amount?.toFixed(NoofDecimal),
    item?.acc_voucher_cr_amt_lx?.toFixed(NoofDecimal),
    item?.particulars,
    // item.profit,
  ]);

  const checkPermissionSub = (data) => {
    let permissions = localStorage.getItem("userPermissionsSub");
    let permissionParsed = JSON.parse(permissions);
    console.log("Permissions for checking", permissionParsed);
    permissionParsed.some((item, index) => {
      if (item.subject === data) {
        console.log("Response while checking");
        item?.action?.some((itm, indx) => {
          console.log("actions", itm);
          if (itm === "create") {
            setCreate(true);
          } else if (itm === "update") {
            setUpdate(true);
          } else if (itm === "read") {
            setRead(true);
          } else if (itm === "delete") {
            setDelete(true);
          }
        });
      }
    });
  };

  console.log("Checking permission by action", Delete, Update, Read, Create);

  useEffect(() => {
    checkPermissionSub("accounts_reports");
  }, []);

  useEffect(() => {
    let today = moment();
    let date = moment().startOf("month");
    AddForm.setFieldsValue({
      rf_hs_date_from: date,
      rf_hs_date_to: today,
    });
  }, []);

  return (
    <div className="container-fluid container_account_report p-3 printcss">
      <div className="">
        <div className="row align-items-center">
          <div className="col-6">
            <h5 className="lead_text">Refund History</h5>
          </div>
          <div className="col-6 d-flex justify-content-end hideOnPrint">
            {data12 && (
              <LeadlistIcons
                datas={data12}
                columns={filteredColumns}
                items={data12}
                xlheading={UnitHeads}
                filename="data.csv"
                name={"Refund History"}
              />
            )}
          </div>
        </div>
        <Form
          form={AddForm}
          name="AddForm"
          onFinish={(value) => {
            if (Read) {
              console.log("Form Submitting Values", value);
              GetRefundHistory(value);
            } else {
              let a = true;
              if (a) {
                message.error("You have No Permission");
              }
              setTimeout(() => {
                a = false;
              }, 5000);
            }
          }}
        >
          <div className="row py-3 hideOnPrint">
          <div className="col-sm-3 col-6">
              <label>Type<span className="required">*</span></label>
              <div className="">
                <Form.Item
                  name={"rf_hs_ledger_type"}
                  rules={[
                    {
                      required: true,
                      message: "Please Select a Type",
                    },
                  ]}
                >
                  <SelectBox
                  onChange={(e) => {
                    GetLedgers(e);
                    AddForm.resetFields(["rf_hs_ledger"])
                  }}
                >
                  {Types &&
                    Types?.length > 0 &&
                    Types.map((item, index) => {
                      return (
                        <Select.Option key={item?.id} value={item?.value}>
                          {item?.value}
                        </Select.Option>
                      );
                    })}
                </SelectBox>
                </Form.Item>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <label>Name</label>
              <div className="">
                <Form.Item
                  name={"rf_hs_ledger"}
                >
                  <SelectBox
                    showSearch={true}
                    allowClear
                    optionFilterProp="children"
                  >
                    {Ledgers &&
                      Ledgers?.length > 0 &&
                      Ledgers.map((item, index) => {
                        console.log("led", item);
                        const optionClassName =
                          item?.freight_type_status !== 1
                            ? "active-option"
                            : "";
                        return (
                          <Select.Option
                            key={item?.acc_ledger_id}
                            value={item?.acc_ledger_id}
                          >
                            {item?.acc_ledger_name}
                          </Select.Option>
                        );
                      })}
                  </SelectBox>
                </Form.Item>
              </div>
            </div>
            <div className="col-sm-3 col-12">
              <lable>Date From</lable>
              <Form.Item
                name={"rf_hs_date_from"}
              >
                <DatePicker
                  format={"DD-MM-YYYY"}
                  value={moment().startOf("month")}
                />
              </Form.Item>
            </div>
            <div className="col-sm-3 col-6">
              <label>Date To</label>
              <div className="">
                <Form.Item
                  name={"rf_hs_date_to"}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          moment(value).isAfter(moment(getFieldValue("rf_hs_date_from")))
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Date To should be greater than Date from by at least same day"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker format={"DD-MM-YYYY"} value={moment()} />
                </Form.Item>
              </div>
            </div>
            
            <div className="col-sm-12 d-flex mt-4 pt-1 px-2 justify-content-center gap-3">
              <Button btnType="save">Search</Button>
              <Button
                type="button"
                onClick={() => {
                  AddForm.resetFields();
                  setTableData([]);
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        </Form>
       </div>

       <div className="container-fluid container_agent_report py-2 datatable">
        {/* <div className="row ">
          <div className="col-6"></div>
          <div className="col-6 d-flex justify-content-end align-items-center gap-3 p-2">
            <span style={{ fontWeight: "600" }}>Openning Balance</span>:
            <span>{OpenningBal?.toFixed(NoofDecimal)} </span>
          </div>
        </div> */}
        <div className="datatable">
          <TableData
            // data={getData(current, pageSize)}
            data={tableData}
            columns={columns}
            // custom_table_css="table_lead_list"
          />
        </div>
        {/* <div className="d-flex justify-content-end py-2">
          <div className="col-1">
            <p style={{ fontWeight: "600" }} className="boldhd">
              Total :
            </p>
          </div>
          <p>{total?.toFixed(NoofDecimal)}</p>
        </div> */}
       </div>
       </div>
  );
}

export default RefundHistory;
